<template>
  <div class="JoinUs_Content_div">
    <div class="JoinUs_top">
      <p>人才招聘</p>
    </div>
    <div class="JoinUs_content">
      <img src="../../../public/img/join_us.png" alt="" />
      <div class="JoinUs_content_text">
        <ul>
          <li><span>招聘岗位：</span><span>商务经理</span></li>
          <li>
            <span>招聘要求：</span>
            <p>
              专科及以上学历，专业不限；具有1年以上知识产权领域销售经验
              ；对saas类软件销售有一定了解，具有较强的执行力、良好的沟通技巧与学习能力；有理想、有抱负，希望透过公司得以发展。
            </p>
          </li>
          <li>
            <span>公司福利：</span>
            <p>无责底薪 + 提成、五险一金、法定带新休假、补充医疗、定期体检</p>
          </li>
        </ul>
      </div>
      <div class="JoinUs_content_top_img">
        <div
          class="hover-div"
          @mouseover="showImage = true"
          @mouseout="showImage = false"
        ></div>
        <img
          v-if="showImage"
          :class="{ 'show-img': showImage }"
          src="../../../public/img/khzx.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="JoinUs_text">
      <span>请发送简历至：zhaosy@tusstar.com 联系方式：15810807269</span>
      <span>公司地址：北京市中关村东路1号院清华科技园启迪科技大楼C座G层</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showImage: false,
    };
  },
};
</script>

<style lang="less" scoped>
.JoinUs_Content_div {
  // height: calc(100vh - 64px - 240px);
  width: 100vw;
  .JoinUs_top {
    width: 100%;
    height: 400px;
    background-image: url("../../../public/img/top_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    p {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
  }
  .JoinUs_text {
    padding: 0 20px 0 20px;
    width: 1200px;
    height: 46px;
    background-color: #3cc7d6;
    border-radius: 8px;
    margin-top: 220px;
    margin-bottom: 30px;
    margin-left: 330px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: right;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .JoinUs_content {
    position: absolute;
    top: 320px;
    left: 17.5%;
    width: 1120px;
    height: 300px;
    padding: 20px 60px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // 添加阴影效果

    text-align: left;

    overflow: hidden; // 防止内容溢出
    transition: box-shadow 0.3s ease; // 添加平滑的过渡效果

    display: flex; // 使用 Flexbox 布局
    align-items: center; // 垂直居中

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // 鼠标悬停时的阴影效果
    }
    img {
      width: 300px;
      height: 300px;
    }
    li {
      margin-bottom: 18px;
    }
    span {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      line-height: 2; // 改变行高，更好地阅读体验
    }
    p {
      color: #999999; // 改变文本颜色
      line-height: 2; // 改变行高，更好地阅读体验
    }
    .JoinUs_content_text {
      position: relative;
    }
    .JoinUs_content_top_img {
      top: 0;
      right: 0;
      width: 220px;
      height: 68px;
      background-image: url("../../../public/img/Icon_code.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      cursor: pointer;
      .hover-div {
        margin-left: 70px;
        margin-top: 10px;
        width: 35px;
        height: 35px;
        position: relative;
      }
      img {
        left: -100px;
        top: 20px;
        width: 120px;
        height: 120px;
        border-radius: 8px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        position: absolute;
      }
    }
  }
}
</style>
