<template>
  <div class="UploadResource_content">
    <div class="UploadResource_div">
      <el-button type="primary" @click="openDialog" class="fb">
        发布信息
      </el-button>
      <el-button type="primary" @click="openDialogs" class="fb">
        类别管理
      </el-button>
      <div style="width: 100%; margin-top: 50px">
        <el-table :data="tableData" border class="custom-table">
          <el-table-column prop="id" label="序号" width="100"></el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="picUrl" label="图片路径"> </el-table-column>
          <el-table-column label="文章链接">
            <template #default="{ row }">
              <a style="color: #409eff" :href="row.wxUrl" target="_Blank">{{
                row.wxUrl
              }}</a>
            </template>
          </el-table-column>
          <el-table-column
            prop="centreName"
            label="类别"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="lastdate"
            label="时间"
            width="150"
          ></el-table-column>
          <el-table-column label="操作" width="200">
            <template #default="{ row }">
              <el-popconfirm
                title="确定删除文章"
                @confirm="handleDelete(row.id)"
                @cancel="handleCancel"
              >
                >
                <template #reference>
                  <el-button type="danger">删除</el-button>
                </template>
              </el-popconfirm>

              <el-button type="warning" @click="handleEdit(row)"
                >修改</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页组件 -->
        <div class="fy">
          <el-pagination
            background
            :current-page="currentPage"
            :page-size="pageSize"
            :total="totalItems"
            layout="prev, pager, next"
            @current-change="handlePageChange"
          />
        </div>
      </div>
    </div>
    <UploadResourceForm
      @refreshList="fetchData"
      :dialogVisible="dialogVisible"
      @update:dialogVisible="dialogVisible = $event"
      @resourceUploaded="handleResourceUploaded"
      :editRow="editRow"
      :isEditMode="isEditMode"
    />
    <CategoryManagement
      :categoryVisible="categoryVisible"
      @updates:categoryVisible="categoryVisible = $event"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { ElMessage } from "element-plus";
import UploadResourceForm from "../views/backstage/UploadResourceForm.vue";
import CategoryManagement from "../views/backstage/CategoryManagement.vue";
import { getResourceCentreList, delResourceCentre } from "../request/index";

const tableData = ref([]);
const currentPage = ref(1); // 当前页码
const pageSize = ref(7); // 每页显示的数据条数
const totalItems = ref(0); // 总数据条数
const dialogVisible = ref(false); // 对话框的显示状态
const categoryVisible = ref(false);
const editRow = ref(null);
const isEditMode = ref(false);

const formatDate = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const processData = (data) => {
  return data.map((item) => ({
    ...item,
    lastdate: formatDate(item.lastdate),
  }));
};

const fetchData = async (page = 1, limit = 7) => {
  try {
    const params = { page, limit };
    const response = await getResourceCentreList(params);
    tableData.value = processData(response.data);
    totalItems.value = response.count;
  } catch (error) {
    console.error("获取数据失败:", error);
  }
};

// 处理页码改变
const handlePageChange = (page) => {
  currentPage.value = page;
  fetchData(page, pageSize.value); // 获取当前页的数据
};

// 删除操作的逻辑
const handleDelete = async (id) => {
  try {
    const response = await delResourceCentre({ id: id });
    if (response.code === 200) {
      ElMessage({
        message: "删除成功",
        type: "success",
      });
      fetchData(currentPage.value, pageSize.value); // 删除后刷新数据
    } else {
      ElMessage.error("删除失败");
    }
  } catch (error) {
    ElMessage.error("删除失败");
  }
};
// 打开对话框
const openDialog = () => {
  dialogVisible.value = true;
  isEditMode.value = false;
};
const openDialogs = () => {
  categoryVisible.value = true;
};

const handleEdit = (row) => {
  console.log(row, "22222222222222");
  editRow.value = row;
  dialogVisible.value = true;
  isEditMode.value = true;
};

// 组件挂载时获取初始数据
onMounted(() => {
  fetchData(currentPage.value, pageSize.value);
});

// 处理上传资源后的逻辑
const handleResourceUploaded = () => {
  fetchData(currentPage.value, pageSize.value); // 上传资源后重新获取数据
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  /* min-height: 480px; */
  /* max-height: 480px; */
  overflow-y: auto;
}
.UploadResource_content {
  height: calc(100vh - 305px);
  display: flex;
  flex-direction: column;
}
.UploadResource_div {
  width: 88%;
  margin: 0 auto;
  margin-top: 15px;
}
.fb {
  float: right;
  margin-left: 15px;
}
.fy {
  margin-top: 15px;
  float: right;
}
.el-table {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.el-table th {
  background-color: #f5f7fa;
  color: #606266;
  font-weight: 600;
}
/deep/ .el-table .cell {
  text-align: center;
}
.image-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.resource-image {
  max-width: 100%;
  height: auto;
  border: 1px solid #ebeef5;
  border-radius: 5px;
}
</style>
