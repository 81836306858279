<!-- <template>
  <div class="PlatformOverview_div">
    <div class="PlatformOverview_content">
      <div class="PlatformOverview_content_top_text">
        <span>一站式平台概览</span>
        <p>
          启迪之星一站式科技服务平台，企业的战略指挥室&企业的虚拟总裁助理，为中小企业主提供一站式的智能化经营解决与指导服务，
          包括但不限于知识产权、财务分析、业务流程、法律咨询和人才培养等方面。
        </p>
      </div>
    </div>

    <div class="PlatformOverview_content_img">
      <div class="PlatformOverview_content_img_left">
        <div class="PlatformOverview_content_Upper_left">
          <span>智能经营</span>
          <p>
            为现代企业打造的全方位解决方案，旨在简化财务管理流程，提高财务透明度，平台提供强大的报告和分析功能，使企业的财务状况一目了然，为企业家和管理者提供决策依据。
          </p>
        </div>

        <div class="PlatformOverview_content_Lower_left">
          <p>
            公司的数据库为系统的核心基础组成模块，公司拥有2亿余件专利文献数据；依托启迪知识产权与国知局基本同步的最完善的实时更新专利大数据平台针对各客户打造的知识产权全流程服务系统。
          </p>
          <span>智能知产</span>
        </div>
      </div>
      <div class="PlatformOverview_content_img_centre">
        <div class="PlatformOverview_content_img_centre_left"></div>
        <div class="PlatformOverview_content_img_centre_circle">
          <div class="PlatformOverview_content_img_centre_circle_div">
            <span>一站式科技服务</span>
          </div>
        </div>
        <div class="PlatformOverview_content_img_centre_right"></div>
      </div>
      <div>
        <div class="PlatformOverview_content_img_right">
          <div class="PlatformOverview_content_Upper_right">
            <span>智能法律</span>
            <p>
              为现代企业打造的全方位法律咨询管理解决方案，旨在简化法务事件处理流程，提高管理效率，使企业的法律服务管理更具战略性和预见性。
            </p>
          </div>

          <div class="PlatformOverview_content_Lower_right">
            <p>
              专注于创新，致力于为客户提供专业的高企和专精特新规划服务，协助客户在市场中获得竞争优势和实现商业成功。
            </p>
            <span>智能政策</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.PlatformOverview_div {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  .PlatformOverview_content {
    display: flex;
    justify-content: center;
    align-items: center;
    .PlatformOverview_content_top_text {
      padding: 100px;
      width: 100%;
      span {
        display: block;
        margin-bottom: 1%;
        font-family: PingFang SC;
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        color: #000;
      }
      p {
        margin: 0 auto;
        width: 55%;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #999;
      }
    }
  }
  .PlatformOverview_content_img {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    position: relative;
    span {
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      color: #ffffff;
    }
    p {
      //   margin: 15px 0 10px 15px;
      width: 284px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #ffffff;
    }

    .PlatformOverview_content_img_centre {
      width: 564px;
      height: 560px;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      position: relative;
      .PlatformOverview_content_img_centre_left {
        width: 282px;
        height: 560px;
        background-image: url("../../../public/img/Subtracty (1).png");
        background-size: contain;
        background-position: 0 top;
        background-repeat: no-repeat;
      }
      .PlatformOverview_content_img_centre_circle {
        width: 380px;
        height: 380px;
        position: absolute;
        top: 90px;
        left: 90px;
        background-image: url("../../../public/img/Group 100.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        .PlatformOverview_content_img_centre_circle_div {
          width: 220px;
          height: 220px;
          background-image: url("../../../public/img/light 1.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            //styleName: T3;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #333333;
          }
        }
      }
      .PlatformOverview_content_img_centre_right {
        width: 282px;
        height: 560px;
        background-image: url("../../../public/img/Subtracty (2).png");
        background-size: contain;
        background-position: 0 top;
        background-repeat: no-repeat;
      }
    }
    .PlatformOverview_content_img_left {
      left: 350px;
      position: absolute;
      .PlatformOverview_content_Upper_left {
        margin-bottom: 15px;
        width: 377.41px;
        height: 208px;
        background-image: url("../../../public/img/Subtract.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        span {
          margin: 15px 0 20px 25px;
        }
        p {
          margin: 0 0 0 25px;
        }
      }
      .PlatformOverview_content_Lower_left {
        width: 377.41px;
        height: 208px;
        background-image: url("../../../public/img/Subtract (2).png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        span {
          margin: 0 0 0 25px;
        }
        p {
          margin: 15px 0 20px 25px;
        }
      }
    }
    .PlatformOverview_content_img_right {
      right: 350px;
      top: 60px;
      position: absolute;
      .PlatformOverview_content_Upper_right {
        margin-bottom: 15px;
        width: 377.41px;
        height: 208px;
        background-image: url("../../../public/img/Subtract (1).png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        span {
          margin: 15px 0 0 250px;
        }
        p {
          margin: 15px 0 20px 70px;
        }
      }
      .PlatformOverview_content_Lower_right {
        width: 377.41px;
        height: 208px;
        background-image: url("../../../public/img/Subtract (3).png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        span {
          margin: 0 15px 0 250px;
        }
        p {
          margin: 15px 0 70px 70px;
        }
      }
    }
  }
}
</style> -->
<template>
  <div class="PlatformOverview_div">
    <div class="PlatformOverview_content">
      <div class="PlatformOverview_content_top_text">
        <span>四大能力</span>
        <p>
          知小风AI助手助力用户实现快速匹配科技成果转化资源、精准招商引才，活化技术潜能，辅助产品研究和投资决策。
        </p>
      </div>
    </div>

    <div class="PlatformOverview_content_img">
      <div class="PlatformOverview_content_img_left">
        <div class="PlatformOverview_content_Upper_left">
          <span>科技成果转化</span>
          <p>
            企业有技术需求或者技术方寻找应用市场时，可以快速准确地寻找技术方和使用方，利用AI在技术领域的理解能力将传统技术无法完成的检索匹配能力升级为精准匹配能力。
          </p>
        </div>

        <div class="PlatformOverview_content_Lower_left">
          <p>
            政府或园区进行精准产业招商，利用AI在产业技术中的理解同时匹配企业相关数据，为招商人员精准指引招商目标群，做到精准招商匹配。
          </p>
          <span>新质生产力引入</span>
        </div>
      </div>
      <div class="PlatformOverview_content_img_centre">
        <div class="PlatformOverview_content_img_centre_left"></div>
        <div class="PlatformOverview_content_img_centre_circle">
          <div class="PlatformOverview_content_img_centre_circle_div">
            <span>知小风AI科创助手</span>
          </div>
        </div>
        <div class="PlatformOverview_content_img_centre_right"></div>
      </div>
      <div>
        <div class="PlatformOverview_content_img_right">
          <div class="PlatformOverview_content_Upper_right">
            <span>研发和投资</span>
            <p>
              在研究新产品和投资决策时，使用AI大模型对技术发展路径进行分析，对参考和调整研究方向或者确定投资标的的技术路径正确具有参考意义。
            </p>
          </div>

          <div class="PlatformOverview_content_Lower_right">
            <p>
              传统专利代理模式需要专业的代理人理解研究人员的技术底层，使用AI科创大模型可便于研究人员随时自动生成专利底稿并自动进行检索。
            </p>
            <span>技术保护</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.PlatformOverview_div {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  .PlatformOverview_content {
    display: flex;
    justify-content: center;
    align-items: center;
    .PlatformOverview_content_top_text {
      padding: 100px;
      width: 100%;
      span {
        display: block;
        margin-bottom: 1%;
        font-family: PingFang SC;
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        color: #000;
      }
      p {
        margin: 0 auto;
        width: 55%;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #999;
      }
    }
  }
  .PlatformOverview_content_img {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    position: relative;
    span {
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      color: #ffffff;
    }
    p {
      //   margin: 15px 0 10px 15px;
      width: 284px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #ffffff;
    }

    .PlatformOverview_content_img_centre {
      width: 564px;
      height: 560px;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      position: relative;
      .PlatformOverview_content_img_centre_left {
        width: 282px;
        height: 560px;
        background-image: url("../../../public/img/Subtracty (1).png");
        background-size: contain;
        background-position: 0 top;
        background-repeat: no-repeat;
      }
      .PlatformOverview_content_img_centre_circle {
        width: 380px;
        height: 380px;
        position: absolute;
        top: 90px;
        left: 90px;
        background-image: url("../../../public/img/Group 100.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        .PlatformOverview_content_img_centre_circle_div {
          width: 220px;
          height: 220px;
          background-image: url("../../../public/img/light 1.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            //styleName: T3;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #333333;
          }
        }
      }
      .PlatformOverview_content_img_centre_right {
        width: 282px;
        height: 560px;
        background-image: url("../../../public/img/Subtracty (2).png");
        background-size: contain;
        background-position: 0 top;
        background-repeat: no-repeat;
      }
    }
    .PlatformOverview_content_img_left {
      left: 350px;
      position: absolute;
      .PlatformOverview_content_Upper_left {
        margin-bottom: 15px;
        width: 377.41px;
        height: 208px;
        background-image: url("../../../public/img/Subtract.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        span {
          margin: 15px 0 20px 25px;
        }
        p {
          margin: 0 0 0 25px;
        }
      }
      .PlatformOverview_content_Lower_left {
        width: 377.41px;
        height: 208px;
        background-image: url("../../../public/img/Subtract (2).png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        span {
          margin: 0 0 0 25px;
        }
        p {
          margin: 15px 0 40px 25px;
        }
      }
    }
    .PlatformOverview_content_img_right {
      right: 350px;
      top: 60px;
      position: absolute;
      .PlatformOverview_content_Upper_right {
        margin-bottom: 15px;
        width: 377.41px;
        height: 208px;
        background-image: url("../../../public/img/Subtract (1).png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        span {
          margin: 15px 0 0 220px;
        }
        p {
          margin: 15px 0 20px 70px;
        }
      }
      .PlatformOverview_content_Lower_right {
        width: 377.41px;
        height: 208px;
        background-image: url("../../../public/img/Subtract (3).png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        span {
          margin: 0 15px 0 244px;
        }
        p {
          margin: 15px 0 50px 70px;
        }
      }
    }
  }
}
</style>
