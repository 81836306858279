<template>
  <ul class="diy-steps">
    <li v-for="(item, index) in stepList" :key="index">
      <div class="diy-step">
        <div
          class="diy-step-line_left"
          v-if="index === 0"
          :style="{
            backgroundImage: 'linear-gradient(180deg, #3fecff, #3fecff)',
          }"
        />
        <!-- 左边线条 -->
        <div
          class="diy-step-line_left"
          v-if="index !== 0"
          :style="{
            backgroundImage: 'linear-gradient(180deg, #3fecff, #3fecff)',
          }"
        />
        <!-- 步骤盒子 -->
        <div
          :class="[
            'diy-step-box',
            {
              'diy-step__success': item.status === 'success',
              'diy-step__fail': item.status === 'fail',
              'diy-step__doing': item.status === 'doing',
              'diy-step__undone': item.status === 'undone',
            },
          ]"
        >
          <span>
            <i
              :style="{
                backgroundColor:
                  item.status === 'doing' ? '#3cc7d6' : '#9ce2ea',
              }"
            ></i>
          </span>
          <strong>{{ item.label }}</strong>
        </div>
        <!-- 右边线条 -->
        <div
          class="diy-step-line_right"
          v-if="index !== stepList.length - 1"
          :style="{
            backgroundImage: 'linear-gradient(180deg, #3fecff, #3fecff)',
          }"
        />
        <div
          class="diy-step-line_right"
          v-if="index !== stepList.length"
          :style="{
            backgroundImage: 'linear-gradient(180deg, #3fecff, #3fecff)',
          }"
        />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["stepList"],
};
</script>

<style lang="less">
.diy-steps {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  position: relative;
  font-family: "微软雅黑";
  overflow: hidden;
}

.diy-steps li {
  width: auto;
  height: 100%;
  flex: 1;
  list-style-type: none;
  font-size: 12px;
  text-align: center;
  position: relative;
  float: left;
}

.diy-steps li .diy-step {
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.diy-step-line_left,
.diy-step-line_right {
  border-bottom: 1px solid transparent;
  background-color: #3cc7d6;
  width: 50%;
  height: 1px;
  display: inline-block;
  position: absolute;
  top: 13px;
  z-index: 0;
}

.diy-step-line_left {
  left: 0;
}

.diy-step-line_right {
  right: 0;
}

.diy-steps li .diy-step .diy-step-box {
  position: relative;
  z-index: 1;
}

.diy-steps li .diy-step .diy-step-box span {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  color: #3fecff;
  text-align: center;
  margin: 0 auto 8px auto;
}

.diy-steps li .diy-step .diy-step-box span i {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  line-height: 13px;
}

.diy-steps li .diy-step .diy-step-box strong {
  letter-spacing: 0.5px;
}

.diy-step__success span {
  border: rgb(#3fecff) 4px solid;
}

.diy-step__success span i {
  background-color: #3cc7d6;
}

.diy-step__success strong {
  color: #3cc7d6;
}

.diy-step__fail span {
  border: rgba(252, 94, 90, 0.2) 4px solid;
  border: rgb(254 223 222) 4px solid;
}

.diy-step__fail span i {
  background-color: #fc5e5a;
}

.diy-step__fail strong {
  color: #fc5e5a;
}

.diy-step__doing span {
  border: 8px solid #d6f3f7;
}

.diy-step__undone span {
  border: 8px solid #d6f3f7;
}

.diy-step__undone span i {
  width: 18px;
  height: 18px;
  background-color: #9ce2ea;
}

.diy-step__undone strong {
  color: #3fecff;
}
</style>
