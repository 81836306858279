import axios from "axios";

// 线上地址
export const baseURL = "https://www.tusstarss.com/searchCompany";

const service = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    return response.data; // 返回响应数据
  },
  (error) => {
    // 请求失败时的处理
    if (error.response) {
      console.error("Error status:", error.response.status); // 打印错误状态码
    } else if (error.request) {
      // 请求已经发出，但是没有收到响应
      console.error("No response received:", error.request); // 打印请求信息
    } else {
      // 在设置请求时发生了错误，触发了一个错误
      console.error("Request error:", error.message); // 打印错误信息
    }
    return Promise.reject(error); // 返回一个 Promise，将错误传递给调用链
  }
);

export default service;
