import service from "./api";

// 上传文件接口 参数 file 文件流
export function planUpLoad(data) {
  return service({
    url: "/planUpLoad",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 获取图片数据流接口 参数url 文件地址
export function getPic(params) {
  return service({
    url: "/getPic",
    method: "GET",
    params: params,
  });
}

//添加数据
export function addResourceCentre(data) {
  return service({
    url: "/addResourceCentre",
    method: "POST",
    data,
  });
}

// 获取列表 参数page 页数 limit条数
export function getResourceCentreList(params) {
  return service({
    url: "/getResourceCentreList",
    method: "GET",
    params: params,
  });
}
// 删除文章
export function delResourceCentre(params) {
  return service({
    url: "/delResourceCentre",
    method: "GET",
    params: params,
  });
}
//更新数据
export function updateResourceCentre(data) {
  return service({
    url: "/updateResourceCentre",
    method: "POST",
    data,
  });
}

//获取全部类型 GET 没有参数
export function getCentreGlobalList(params) {
  return service({
    url: "/getCentreGlobalList",
    method: "GET",
    params: params,
  }); 
}

//删除类型
export function delCentreGlobal(params) {
  return service({
    url: "/delCentreGlobal",
    method: "GET",
    params: params,
  });
}
//添加类型 
export function addCentreGlobal(data) {
  return service({
    url: "/addCentreGlobal",
    method: "POST",
    data,
  });
}