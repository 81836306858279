<!-- <template>
  <div v-if="activeIndexs === -1">
    <TopBackground />
    <PlatformOverview />
    <PlatformAdvantage />
  </div>
  <div v-if="activeIndexs === 0">
    <PlatformOverview />
    <PlatformAdvantage />
  </div>
  <div v-if="activeIndexs === 1">
    <PlatformAdvantage />
  </div>
</template>

<script>
import TopBackground from "../views/components/CoreProduct/TopBackground.vue";
import PlatformOverview from "../views/components/CoreProduct/PlatformOverview.vue";
import PlatformAdvantage from "../views/components/CoreProduct/PlatformAdvantage.vue";

export default {
  components: {
    TopBackground,
    PlatformOverview,
    PlatformAdvantage,
  },
  props: {
    activeIndexs: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped></style> -->

<template>
  <div>
    <TopBackground />
    <PlatformOverview />
    <PlatformAdvantage />
  </div>
  <!-- <div v-if="activeIndexs === 0">
    <PlatformOverview />
    <PlatformAdvantage />
  </div> -->
  <!-- <div v-if="activeIndexs === 1">
    <TopBackground />
    <PlatformOverview />
    <PlatformAdvantage />
  </div> -->
</template>

<script>
import TopBackground from "../views/components/CoreProduct/TopBackground.vue";
import PlatformOverview from "../views/components/CoreProduct/PlatformOverview.vue";
import PlatformAdvantage from "../views/components/CoreProduct/PlatformAdvantage.vue";

export default {
  components: {
    TopBackground,
    PlatformOverview,
    PlatformAdvantage,
  },
  props: {
    activeIndexs: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped></style>
