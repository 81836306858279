<template>
  <div class="KejiService_Content_div">
    <div class="KejiService_top">
      <div>
        <p>科技服务</p>
        <span> 知识产权服务，专业守护，精准评估，实现知识产权价值最大化 </span>
      </div>
    </div>
    <div class="KejiService_swiper">
      <div class="KejiService_Lits_text">
        <ul>
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ active: index === currentIndex }"
            @mouseenter="stopAutoPlay"
            @mouseleave="startAutoPlay"
            @mouseover="showCard(index)"
          >
            <span :class="{ Highlight: index === currentIndex }">{{
              tab
            }}</span>
          </li>
        </ul>
      </div>
      <div class="KejiService_Lits">
        <ul>
          <li
            v-for="(card, index) in cards"
            :key="index"
            :class="{ KejiService_li_Toggle: index === currentIndex }"
            @mouseenter="stopAutoPlay"
            @mouseleave="startAutoPlay"
          >
            <div class="KejiService_li_content">
              <span :class="{ Highlight: index === currentIndex }">{{
                card.title
              }}</span>
              <p>{{ card.description }}</p>
              <div v-if="index === currentIndex" v-html="card.text"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: ["知识产权申请", "知识产权托管", "知识产权挖掘", "企业能力评估"],
      cards: [
        {
          title: "知识产权申请",
          description: "保护创新，守护价值",
          text: `拥有专业的知识产权服务团队，可为客户提供全面的知识产权代理和规划服务。 \n\n\n\n专业团队确保服务优质高效，处理时长缩短至行业平均65%。</br> </br>
                平台提供一站式知识产权申请与管理服务，实现有形与无形资产的高效整合，助力企业资产增值与发展。`,
        },
        {
          title: "知识产权托管",
          description: "全方位托管服务",
          text: `专业托管：企业可将企知识产权项目委托给国知企服平台进行管理，由专业团队进行管理和运营，使企业能够更专注于自身的核心业务。</br> </br>

定制化服务：平台提供定制化的服务，如专利到期提醒和自动办理续费等功能，采用一站式处理方式，高效快捷地保护企业知识产权。</br> </br>

知识产权的价值转化：企业可以授权平台进行专利转让托管，由平台代为进行知识产权的展示，寻找合适的买家，助力企业更好地实现知识产权的价值转化，将其转化为实际的商业利益。`,
        },
        {
          title: "知识产权挖掘",
          description: "深度挖掘知识产权专利信息",
          text: `多维度的挖掘能力：提供多角度的竞争对手挖掘功能，支持对企业和技术方向的双重维度挖掘，使企业能够深入了解竞争对手的专利布局、技术实力和创新动向。</br> </br>

全面的专利信息获取：通过挖掘，企业可尽早发现竞争对手的重要专利，掌握其技术特点和潜在商业价值，企业可以及时规避设计风险，避免侵犯他人专利权，或在必要时主动出击进行专利侵权追诉。`,
        },
        {
          title: "企业能力评估",
          description: "领先一步，探索企业成长新境界",
          text: `通过国家级高新技术资质认证视角，为企业提供专业资质诊断评估。</br> </br> 雷达图直观展示企业能力，绿色、红色标识清晰呈现企业优势与不足。</br> </br> 实时跟踪企业成长轨迹，及时掌握企业能力变化。</br> </br> 提供详尽的评估报告与针对性建议。`,
        },
      ],
      currentIndex: 0,
      interval: null,
    };
  },
  mounted() {
    this.startAutoPlay();
  },
  beforeDestroy() {
    this.stopAutoPlay();
  },
  methods: {
    showCard(index) {
      this.currentIndex = index;
    },
    startAutoPlay() {
      this.interval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.tabs.length;
      }, 5000);
    },
    stopAutoPlay() {
      clearInterval(this.interval);
    },
  },
};
</script>
<style lang="less" scoped>
.KejiService_Content_div {
  .KejiService_top {
    width: 100%;
    height: 400px;
    background-image: url("../public/img/top_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    justify-content: center;
    p {
      margin: 33% 0 8% 0;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: "#333333";
    }
  }
  .KejiService_swiper {
    width: 100%;
    .KejiService_Lits_text {
      ul {
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        li {
          width: 360px;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          span {
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #333333;
            cursor: pointer;
          }
        }
        .Highlight {
          color: #3cc7d6;
        }
      }
    }
    .KejiService_Lits {
      margin: 10px;
      width: 100%;
      display: flex; /* 将父元素设置为 flex 容器 */
      justify-content: center; /* 水平居中对齐 */
      ul {
        display: flex;
        li {
          width: 208px;
          height: 424px;
          border-radius: 8px;
          margin-left: 15px;
          background-image: url(../public/img/Technology_services_img.png);
          background-size: cover; /* 根据需要调整 */
          background-position: right 0 bottom 0; /* 图片右偏移10px，下偏移20px */
          background-repeat: no-repeat; /* 防止背景图重复 */
          transition: all 0.5s ease; /* 添加过渡效果 */
        }

        .KejiService_li_Toggle {
          position: relative; /* 使伪元素相对于此元素定位 */
          width: 536px;
          height: 424px;
          border-radius: 8px;
          background-image: url(../public/img/Technology_services_img.png);
          background-size: contain;
          background-position: right; /* 图片靠右，垂直居中 */
          background-repeat: no-repeat; /* 防止图片重复 */
          overflow: hidden;
        }
        .KejiService_li_Toggle::before {
          content: ""; /* 生成一个空的伪元素 */
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7); /* 50% 透明度的黑色 */
          border-radius: inherit; /* 继承父元素的圆角 */
          z-index: 1; /* 放置在文本和背景图片之间 */
        }
        .KejiService_li_content {
          position: relative; /* 确保内容显示在遮罩上面 */
          z-index: 2; /* 高于遮罩 */
          color: black; /* 确保文本在浅色遮罩上可读 */
          padding: 30px 0 0 26px; /* 添加内边距使文本与边界保持距离 */
          span {
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            text-align: left;
            color: #333333;
          }
          p {
            width: 160px;
            margin-top: 26px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #666666;
          }
          div {
            margin-top: 20px;
            width: 98%;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #333333;
          }
          .Highlight {
            color: #3cc7d6;
          }
        }
      }
    }
  }
}
</style>
