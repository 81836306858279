// 设置 rem 函数
function setRem() {
  // 1920 默认大小16px; 1920px = 120rem ;每个元素px基础上/16
  const screenWidth = 1900;
  const scale = screenWidth / 16;
  const htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  // 得到html的Dom元素
  const htmlDom = document.getElementsByTagName("html")[0];
  // 设置根元素字体大小
  htmlDom.style.fontSize = htmlWidth / scale + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};

// function setRem() {
//   const baseWidth = 1920;
//   const baseFontSize = 16;
//   const minFontSize = 12; // 设置最小字体大小，防止字体过小
//   const maxFontSize = 20; // 设置最大字体大小，防止字体过大
//   const scale = baseWidth / baseFontSize;
//   const screenWidth =
//     document.documentElement.clientWidth || document.body.clientWidth;
//   const fontSize = Math.min(
//     Math.max(screenWidth / scale, minFontSize),
//     maxFontSize
//   );
//   const htmlDom = document.getElementsByTagName("html")[0];
//   htmlDom.style.fontSize = fontSize + "px";
// }

// window.addEventListener("resize", setRem);
// setRem();
