<template>
  <div class="Home_maximum_box">
    <Head @updateActiveIndex="handleUpdateActiveIndex"></Head>
    <router-view :activeIndexs="activeIndexs" />
    <Bottom></Bottom>
    <FixedConsultation></FixedConsultation>
  </div>
</template>

<script>
import "@/style/FrameLayout.less";
import Head from "@/views/components/Head.vue";
import Bottom from "@/views/components/Bottom.vue";
import FixedConsultation from "@/views/components/FixedConsultation.vue";
export default {
  components: {
    Head,
    Bottom,
    FixedConsultation,
  },
  data() {
    return {
      activeIndexs: 0, // 默认显示PlatformOverview
    };
  },
  methods: {
    handleUpdateActiveIndex(index) {
      console.log(index, "333333333");
      this.activeIndexs = index;
    },
  },
};
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
</script>
