<template>
  <div v-if="categoryVisible" class="custom-dialog">
    <div class="dialog-content">
      <el-form ref="uploadForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="代号" prop="code">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="codeName">
          <el-input v-model="form.codeName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit">点击创建</el-button>
          <el-button @click="cancel">关闭弹框</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="categoryOptions"
        border
        height="200"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column prop="value" label="代号"> </el-table-column>
        <el-table-column prop="label" label="名称"> </el-table-column>
        <el-table-column label="操作">
          <template #default="{ row }">
            <el-popconfirm
              title="确定会删除这个类型所有文章！！"
              @confirm="handleDelete(row.value)"
              @cancel="handleCancel"
            >
              >
              <template #reference>
                <el-button type="danger">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getCentreGlobalList,
  delCentreGlobal,
  addCentreGlobal,
} from "../../request/index";

export default {
  props: {
    categoryVisible: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.fetchCategoryOptions();
  },
  data() {
    return {
      categoryOptions: [],
      form: {
        code: "",
        codeName: "",
      },
      rules: {
        code: [{ required: true, message: "请输入代号", trigger: "blur" }],
        codeName: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
    };
  },
  methods: {
    async fetchCategoryOptions() {
      try {
        const response = await getCentreGlobalList();
        this.categoryOptions = response.data.map((item) => ({
          label: item.codeName, // 假设接口返回的数据中有name字段
          value: item.code, // 假设接口返回的数据中有id字段
        }));
      } catch (error) {
        console.error("Error fetching category options:", error);
      }
    },
    async handleSubmit() {
      try {
        await this.$refs.uploadForm.validate();
        const isDuplicate = this.categoryOptions.some(
          (option) => option.label === this.form.codeName
        );
        if (isDuplicate) {
          this.$message.error("不能添加相同类型");
          return;
        }
        // 调用 addCentreGlobal 接口
        const res = await addCentreGlobal({
          code: this.form.code,
          codeName: this.form.codeName,
        });
        if (res.code === 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          await this.fetchCategoryOptions();
        } else {
          this.$message.error("添加失败");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },

    // 删除类别
    async handleDelete(code) {
      console.log(code, "99999999");
      try {
        const res = await delCentreGlobal({ code: code });
        if (res.code === 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.$nextTick(() => {
            this.fetchCategoryOptions();
          });
        } else {
          this.$message.error("删除失败");
        }
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    },
    cancel() {
      this.$emit("updates:categoryVisible", false);
    },
  },
};
</script>

<style scoped>
.custom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px;
  position: relative;
}

.upload-demo .el-upload {
  display: inline-block;
}

.uploaded-image {
  margin-top: 10px;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-button {
  margin-right: 10px;
}

.el-button[type="primary"] {
  background-color: #409eff;
  border-color: #409eff;
}

.el-button[type="success"] {
  background-color: #67c23a;
  border-color: #67c23a;
}

.el-button[type="success"]:hover {
  background-color: #4caf50;
  border-color: #4caf50;
}
</style>
