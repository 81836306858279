<template>
  <div class="Bottom_List_div">
    <ul>
      <li>
        <img src="@\public\img\Group 91.png" alt="" />
      </li>
      <li>
        <img src="@\public\img\Group 92.png" alt="" />
      </li>
      <li>
        <img src="@\public\img\Group 93.png" alt="" />
      </li>
      <li>
        <img src="@\public\img\Group 94.png" alt="" />
      </li>
    </ul>
  </div>
</template>

<style lang="less" scoped>
.Bottom_List_div {
  width: 100%;
  margin: 0 auto;
  display: block; /* 确保 ul 是块级元素 */
  width: fit-content; /* 使 ul 根据其内容调整宽度 */
  ul {
    display: flex;
    margin: 54px 0 54px 0;
    li {
      width: 290px;
      height: 140px;
      border: 2px dashed #3cc7d6; /* 添加2px宽的虚线边框，颜色为#3cc7d6 */
      box-sizing: border-box; /* 包括边框和内边距在内计算宽高 */
      display: flex; /* 使子元素的大小可以根据父元素调整 */
      align-items: center; /* 水平居中 */
      justify-content: center; /* 垂直居中 */
      margin: 0 5px 0 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
