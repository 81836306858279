<!-- <template>
  <div class="PlatformAdvantage_div">
    <div class="PlatformAdvantage_content">
      <div class="PlatformAdvantage_content_top_text">
        <span>一站式平台优势</span>
        <p>一站式科技服务，打造智能化经营新模式。</p>
      </div>
    </div>
    <div class="PlatformAdvantage_Content_rotation">
      <ul>
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="{ active: currentIndex === index }"
          @mouseenter="setCurrentIndex(index)"
          @mouseleave="resetIndex"
        >
          <img :src="item.imgSrc" alt="" />
          <span :class="{ active_color: currentIndex === index }">{{
            item.title
          }}</span>
          <p v-if="currentIndex === index">{{ item.description }}</p>
        </li>
      </ul>
    </div>
    <div class="PlatformAdvantage_Content_rotation_list">
      <ul v-for="(item, listIndex) in displayLists" :key="listIndex">
        <li>
          <img :src="item.imgSrc" alt="" />
          <span>{{ item.title }}</span>
          <p>{{ item.description }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          imgSrc: require("../../../public/img/图层6 1.png"),
          title: "企业管理强准化",
          description:
            "数据处理与分析的领航者，助力企业洞悉经营现状，探索未来趋势，实现决策升级",
        },
        {
          imgSrc: require("../../../public/img/图层5 2.png"),
          title: "企业管理可视化",
          description:
            "智能AI赋能产品科技，清晰了解公司运营脉搏，轻松实现高效管理",
        },
        {
          imgSrc: require("../../../public/img/图层4 2.png"),
          title: "政策获取简单化",
          description:
            "政策可视化服务覆盖全行业，成为企业应对政策变化、制定战略决策的有力工具",
        },
      ],
      lists: [
        [
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_01.png"),
            title: "强大的数据处理能力：",
            description:
              "快速导入、整合和分析海量数据，包括财务、销售和市场数据，为企业提供全面且准确的数据支持。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_02.png"),
            title: "灵活的数据导入方式：",
            description:
              "支持主流财务管理软件的数据导入，同时也支持用户自助上传财务数据，满足企业不同的数据需求。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_03.png"),
            title: "深度数据分析功能：",
            description:
              "通过提供企业经营状况分析图表，帮助企业深入研究经营数据，揭示数据中的规律和趋势，增强企业对自身经营状况的了解。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_04.png"),
            title: "智能预测功能：",
            description:
              "基于历史数据，通过经营预测模型，为企业提供多维度经营预测项目，如营业收入、成本、负债和净利润预测等。",
          },
        ],
        [
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_05.png"),
            title: "智能化业务跟踪：",
            description:
              "通过配备的智能化业务跟踪系统，企业主可以实时跟踪、监控和分析业务过程、活动和结果，有助于主动探查业务进展情况。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_06.png"),
            title: "可视化数据管理：",
            description:
              "支持对接主流企业IM的常规数据，并将其转化为可视化图表，提供以企业主视角为观察维度的管理面板。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_07.png"),
            title: "决策优化：",
            description:
              "通过对业务数据的分析和挖掘，帮助企业主更好了解市场需求和趋势，优化业务流程、决策、产品和服务。",
          },
        ],
        [
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_08.png"),
            title: "高效整合政策信息：",
            description:
              "通过部署自动政策收集展示的系统，整合多个政策平台的信息，以可视化图表展示，便于企业实现个性化定制和订阅。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_09.png"),
            title: "节省企业资源：",
            description:
              "自动化政策收集减少企业人力和时间投入，助其专注经营生产。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_10.png"),
            title: "精准政策指导：",
            description:
              "确保企业及时获取最新政策，为其提供精准政策指导和支持。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_11.png"),
            title: "结合能力评估：",
            description:
              "平台结合能力评估系统，帮助企业了解自身优劣势，制定提升计划。",
          },
        ],
      ],
      currentIndex: 0,
      rotationInterval: null,
      isHovering: false,
    };
  },
  computed: {
    displayLists() {
      return this.lists[this.currentIndex] || [];
    },
  },
  mounted() {
    this.startRotation();
  },
  methods: {
    startRotation() {
      this.rotationInterval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.items.length;
      }, 5000);
    },
    setCurrentIndex(index) {
      this.isHovering = true;
      this.currentIndex = index;
    },
    resetIndex() {
      this.isHovering = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.rotationInterval);
  },
};
</script>

<style lang="less" scoped>
.PlatformAdvantage_div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  .PlatformAdvantage_content {
    display: flex;
    justify-content: center;
    align-items: center;
    .PlatformAdvantage_content_top_text {
      padding: 100px;
      width: 100%;
      span {
        display: block;
        margin-bottom: 1%;
        font-family: PingFang SC;
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        color: #000;
      }
      p {
        margin: 0 auto;
        width: 55%;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #999;
      }
    }
  }
  .PlatformAdvantage_Content_rotation {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .PlatformAdvantage_Content_rotation ul {
    display: flex;
  }
  .PlatformAdvantage_Content_rotation li {
    cursor: pointer;
    margin-left: 15px;
    width: 392px;
    height: 388px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .PlatformAdvantage_Content_rotation li span {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #333333;
  }
  .PlatformAdvantage_Content_rotation li p {
    margin-top: 15px;
    width: 360px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #999999;
  }
  .PlatformAdvantage_Content_rotation li img {
    width: 360px;
    height: 250px;
  }
  .PlatformAdvantage_Content_rotation .active {
    background-image: url("../../../public/img/list_Content_rotation.png");
  }
  .PlatformAdvantage_Content_rotation .active_color {
    color: #3cc7d6;
  }

  .PlatformAdvantage_Content_rotation_list {
    width: 1208px;
    height: 280px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 15px;
    margin-top: 15px;
    ul {
      li {
        padding: 15px 10px 10px 40px;
        display: flex;
        align-items: center;
        height: 40px;
        img {
          width: 40px;
          height: 40px;
        }
        span {
          //styleName: T4;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #3cc7d6;
        }
        p {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #333333;
        }
      }
    }
  }
}
</style> -->

<template>
  <div class="PlatformAdvantage_div">
    <div class="PlatformAdvantage_content">
      <div class="PlatformAdvantage_content_top_text">
        <span>场景示例</span>
        <p>丰富的应用场景，满足高校、企业、园区等多方需求，更多能力敬请期待</p>
      </div>
    </div>
    <div class="PlatformAdvantage_Content_rotation">
      <ul>
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="{ active: currentIndex === index }"
          @mouseenter="setCurrentIndex(index)"
          @mouseleave="resetIndex"
        >
          <img :src="item.imgSrc" alt="" />
          <span :class="{ active_color: currentIndex === index }">{{
            item.title
          }}</span>
          <p v-if="currentIndex === index">{{ item.description }}</p>
        </li>
      </ul>
    </div>
    <!-- <div class="PlatformAdvantage_Content_rotation_list">
      <ul v-for="(item, listIndex) in displayLists" :key="listIndex">
        <li>
          <img :src="item.imgSrc" alt="" />
          <span>{{ item.title }}</span>
          <p>{{ item.description }}</p>
        </li>
      </ul>
    </div> -->
    <div
      class="PlatformAdvantage_Content_rotation_list"
      @mouseenter="setCurrentIndex(currentIndex)"
      @mouseleave="resetIndex"
    >
      <div
        v-for="(item, listIndex) in displayLists"
        :key="listIndex"
        class="PlatformAdvantage_Content_rotation_item"
      >
        <video :src="item.imgSrc" autoplay muted loop></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          imgSrc: require("../../../public/img/图层6 1.png"),
          title: `科技成果转换(我有技术找应用场景)`,
          description: "为拥有技术的高校或科研院所，寻找合适的落地企业",
        },
        {
          imgSrc: require("../../../public/img/图层5 2.png"),
          title: "科技成果转换(我有应用场景找技术升级)",
          description: "为企业寻找需要的技术或合作企业",
        },
        {
          imgSrc: require("../../../public/img/图层4 2.png"),
          title: "新质生产力引入(招商引才)",
          description: "为政府/园区匹配合适的高技术企业和高层次人才",
        },
      ],
      lists: [
        [
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_01.png"),
            title: "强大的数据处理能力：",
            description:
              "快速导入、整合和分析海量数据，包括财务、销售和市场数据，为企业提供全面且准确的数据支持。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_02.png"),
            title: "灵活的数据导入方式：",
            description:
              "支持主流财务管理软件的数据导入，同时也支持用户自助上传财务数据，满足企业不同的数据需求。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_03.png"),
            title: "深度数据分析功能：",
            description:
              "通过提供企业经营状况分析图表，帮助企业深入研究经营数据，揭示数据中的规律和趋势，增强企业对自身经营状况的了解。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_04.png"),
            title: "智能预测功能：",
            description:
              "基于历史数据，通过经营预测模型，为企业提供多维度经营预测项目，如营业收入、成本、负债和净利润预测等。",
          },
        ],
        [
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_05.png"),
            title: "智能化业务跟踪：",
            description:
              "通过配备的智能化业务跟踪系统，企业主可以实时跟踪、监控和分析业务过程、活动和结果，有助于主动探查业务进展情况。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_06.png"),
            title: "可视化数据管理：",
            description:
              "支持对接主流企业IM的常规数据，并将其转化为可视化图表，提供以企业主视角为观察维度的管理面板。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_07.png"),
            title: "决策优化：",
            description:
              "通过对业务数据的分析和挖掘，帮助企业主更好了解市场需求和趋势，优化业务流程、决策、产品和服务。",
          },
        ],
        [
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_08.png"),
            title: "高效整合政策信息：",
            description:
              "通过部署自动政策收集展示的系统，整合多个政策平台的信息，以可视化图表展示，便于企业实现个性化定制和订阅。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_09.png"),
            title: "节省企业资源：",
            description:
              "自动化政策收集减少企业人力和时间投入，助其专注经营生产。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_10.png"),
            title: "精准政策指导：",
            description:
              "确保企业及时获取最新政策，为其提供精准政策指导和支持。",
          },
          {
            imgSrc: require("../../../assets/icon/Icon_core_technology_11.png"),
            title: "结合能力评估：",
            description:
              "平台结合能力评估系统，帮助企业了解自身优劣势，制定提升计划。",
          },
        ],
      ],
      listvideo: [
        { imgSrc: require("../../../public/video/1.mp4") },
        { imgSrc: require("../../../public/video/2.mp4") },
        { imgSrc: require("../../../public/video/3.mp4") },
      ],
      currentIndex: 0,
      rotationInterval: null,
      isHovering: false,
    };
  },
  computed: {
    displayLists() {
      return [this.listvideo[this.currentIndex]];
    },
  },
  mounted() {
    this.startRotation();
  },
  methods: {
    // startRotation() {
    //   this.rotationInterval = setInterval(() => {
    //     this.currentIndex = (this.currentIndex + 1) % this.items.length;
    //   }, 6000);
    // },
    startRotation() {
      this.rotationInterval = setInterval(() => {
        if (!this.isHovering) {
          this.currentIndex = (this.currentIndex + 1) % this.listvideo.length;
        }
      }, 10000);
    },
    setCurrentIndex(index) {
      this.isHovering = true;
      this.currentIndex = index;
    },
    resetIndex() {
      this.isHovering = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.rotationInterval);
  },
};
</script>

<style lang="less" scoped>
.PlatformAdvantage_div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  .PlatformAdvantage_content {
    display: flex;
    justify-content: center;
    align-items: center;
    .PlatformAdvantage_content_top_text {
      padding: 100px;
      width: 100%;
      span {
        display: block;
        margin-bottom: 1%;
        font-family: PingFang SC;
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        color: #000;
      }
      p {
        margin: 0 auto;
        width: 77%;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #999;
      }
    }
  }
  .PlatformAdvantage_Content_rotation {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .PlatformAdvantage_Content_rotation ul {
    display: flex;
  }
  .PlatformAdvantage_Content_rotation li {
    cursor: pointer;
    margin-left: 15px;
    width: 392px;
    height: 388px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .PlatformAdvantage_Content_rotation li span {
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #333333;
  }
  .PlatformAdvantage_Content_rotation li p {
    margin-top: 15px;
    width: 360px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #999999;
  }
  .PlatformAdvantage_Content_rotation li img {
    width: 360px;
    height: 250px;
  }
  .PlatformAdvantage_Content_rotation .active {
    background-image: url("../../../public/img/list_Content_rotation.png");
  }
  .PlatformAdvantage_Content_rotation .active_color {
    color: #3cc7d6;
  }

  .PlatformAdvantage_Content_rotation_list {
    width: 1200px;
    height: 600px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 40px;
    // ul {
    //   li {
    //     padding: 15px 10px 10px 40px;
    //     display: flex;
    //     align-items: center;
    //     height: 40px;
    //     img {
    //       width: 40px;
    //       height: 40px;
    //     }
    //     span {
    //       //styleName: T4;
    //       font-family: PingFang SC;
    //       font-size: 16px;
    //       font-weight: 400;
    //       line-height: 24px;
    //       text-align: left;
    //       color: #3cc7d6;
    //     }
    //     p {
    //       font-family: PingFang SC;
    //       font-size: 16px;
    //       font-weight: 400;
    //       line-height: 24px;
    //       text-align: left;
    //       color: #333333;
    //     }
    //   }
    // }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover; /* 使视频覆盖整个父级盒子 */
      top: 0;
      left: 0;
    }
  }
}
</style>
