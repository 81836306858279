<template>
  <MainContent></MainContent>
</template>
<script>
import MainContent from "./components/MainContent.vue";
export default {
  components: {
    MainContent,
  },
};
</script>
