<template>
  <div class="Home_top_box">
    <div class="Home_top_left">
      <div class="Home_top_left_img">
        <span>国知启迪之星</span>
      </div>
      <ul>
        <li
          v-for="(item, index) in navItems"
          :key="index"
          @mouseover="
            highlightItem(index);
            checkCoreProducts(index);
          "
          @mouseleave="
            index === 4 ? '' : resetHighlight();
            hideCoreProducts(index);
          "
          @click="selectItem(index)"
        >
          <router-link v-if="item.route" :to="item.route">
            <span :class="{ active: activeIndex === index || item.selected }">{{
              item.label
            }}</span>
          </router-link>
          <span
            v-else
            :class="{ active: activeIndex === index || item.selected }"
            >{{ item.label }}</span
          >
        </li>
      </ul>
    </div>
    <div class="Home_top_right">
      <div class="Home_telephone">
        <div class="Home_top_right_img">
          <img src="@/assets/icon/Icon_phone.png" alt="" />
        </div>
        <p>咨询电话：010-5723-8723</p>
      </div>
      <div class="disembark_button" @click="redirectToWebsite">
        知小风AI助手
      </div>
    </div>
  </div>
  <!-- 循环显示的抽屉部分 -->
  <div
    class="AboutUs_list"
    v-if="showAboutUsDrawer"
    @mouseover="isMouseOverDrawer = true"
    @mouseleave="
      isMouseOverDrawer = false;
      leaveAboutUsNavItem();
    "
  >
    <ul v-for="(group, groupIndex) in aboutUsGroups" :key="groupIndex">
      <li v-for="(link, linkIndex) in group.links" :key="linkIndex">
        <router-link v-if="link.route" :to="link.route">
          <span
            :class="{
              active:
                activeGroupIndex === groupIndex &&
                activeLinkIndex === linkIndex,
            }"
            >{{ link.label }}</span
          >
        </router-link>
        <span
          v-else
          :class="{
            active:
              activeGroupIndex === groupIndex && activeLinkIndex === linkIndex,
          }"
          >{{ link.label }}</span
        >
      </li>
    </ul>
  </div>
  <!-- <div
    class="CoreProducts_list"
    v-if="showCoreProductsList"
    @mouseover="cancelHideCoreProducts()"
    @mouseleave="delayedHideCoreProducts()"
  >
    <ul>
      <li>
        <span @click="setActive(1)" :class="{ active: activeIndexs === 1 }"
          >AI助手知小风</span
        >
      </li>
      <li @click="setActive(0)">
        <span :class="{ active: activeIndexs === 0 }">一站式平台概览</span>
      </li>
      <li @click="setActive(1)">
        <span :class="{ active: activeIndexs === 1 }">一站式平台优势</span>
      </li>
    </ul>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      navItems: [
        { label: "首页", route: "/HomeView", selected: false },
        { label: "核心产品", route: "/CoreProduct", selected: false },
        { label: "科技服务", route: "/KejiService", selected: false },
        { label: "资源中心", route: "/ResourceCenter", selected: false },
        {
          label: "关于我们",
          route: "/AboutUs/Overview",
          selected: false,
          aboutUsGroups: [
            {
              links: [
                { label: "公司介绍" },
                { label: "公司概况", route: "/AboutUs/Overview" },
                { label: "公司荣誉", route: "/AboutUs/Overview" },
              ],
            },
            {
              links: [
                { label: "公司动态" },
                { label: "新闻动态", route: "/AboutUs/NewsUpdates" },
                // { label: "公司活动", route: "/AboutUs/NewsUpdates" },
              ],
            },
            {
              links: [
                { label: "人才招聘" },
                { label: "加入我们", route: "/AboutUs/JoinUs" },
              ],
            },
          ],
        },
        { label: "联系我们", route: "/ContactUs", selected: false },
      ],
      activeIndex: -1,
      activeGroupIndex: -1,
      activeLinkIndex: -1,
      showAboutUsDrawer: false,
      aboutUsGroups: [],
      showCoreProductsList: false,
      hideCoreProductsTimer: null,
      activeIndexs: -1, // 初始值为-1，表示没有选中的项
    };
  },
  watch: {
    $route(to) {
      this.updateActiveItem(to.path);
    },
  },
  created() {
    this.updateActiveItem(this.$route.path);
  },
  methods: {
    redirectToWebsite() {
      window.location.href = "https://www.tusstarss.com/AI/#/NewHome";
    },
    setActive(index) {
      this.activeIndexs = index;
      this.$emit("updateActiveIndex", index);
    },
    // 高亮鼠标划过的导航项
    highlightItem(index) {
      this.activeIndex = index;
      // 当鼠标划入“关于我们”时，显示抽屉
      if (index === 4) {
        this.showAboutUsDrawer = true;
        this.aboutUsGroups = this.navItems[index].aboutUsGroups; // 设置抽屉内容
      } else {
        this.showAboutUsDrawer = false;
      }
    },
    // 重置高亮状态
    resetHighlight() {
      this.activeIndex = -1;
      this.activeGroupIndex = -1;
      this.activeLinkIndex = -1;
    },
    // 高亮关于我们链接
    highlightAboutUsLink(groupIndex, linkIndex) {
      this.activeGroupIndex = groupIndex;
      this.activeLinkIndex = linkIndex;
    },
    closeAboutUsDrawer() {
      // 专门用于关闭抽屉的方法
      this.showAboutUsDrawer = false;
    },
    // 选中导航项
    selectItem(index) {
      // 当点击时，更新选中状态
      this.navItems.forEach((item, idx) => {
        item.selected = idx === index;
      });
      if (index === 1) {
        this.activeIndexs = -1;
        this.$emit("updateActiveIndex", this.activeIndexs);
      }
    },
    // 检查是否显示核心产品列表
    checkCoreProducts(index) {
      if (index === 1) {
        this.showCoreProductsList = true;
        // 清除隐藏核心产品列表的计时器
        if (this.hideCoreProductsTimer) {
          clearTimeout(this.hideCoreProductsTimer);
          this.hideCoreProductsTimer = null;
        }
      }
    },
    // 隐藏核心产品列表
    hideCoreProducts(index) {
      if (index === 1) {
        this.delayedHideCoreProducts();
      }
    },
    // 当鼠标离开抽屉时，关闭抽屉
    leaveAboutUsDrawer() {
      this.showAboutUsDrawer = false;
    },
    // 新增方法，当鼠标离开“关于我们”导航项时调用
    leaveAboutUsNavItem() {
      this.resetHighlight();
      // 延迟关闭抽屉，防止鼠标直接从导航项划到抽屉导致抽屉关闭
      setTimeout(() => {
        if (!this.isMouseOverDrawer) {
          this.closeAboutUsDrawer();
        }
      }, 100);
    },
    // 根据当前路径更新导航项高亮
    // updateActiveItem(path) {
    //   this.navItems.forEach((item, index) => {
    //     if (item.route && path.startsWith(item.route)) {
    //       this.activeIndex = index;
    //       item.selected = true;
    //       // 如果是关于我们，显示抽屉并高亮对应链接
    //       if (index === 4) {
    //         this.showAboutUsDrawer = true;
    //         this.aboutUsGroups = item.aboutUsGroups;
    //         item.aboutUsGroups.forEach((group, groupIndex) => {
    //           group.links.forEach((link, linkIndex) => {
    //             if (link.route && path === link.route) {
    //               this.activeGroupIndex = groupIndex;
    //               this.activeLinkIndex = linkIndex;
    //             }
    //           });
    //         });
    //       } else {
    //         this.showAboutUsDrawer = false;
    //       }
    //     } else {
    //       item.selected = false;
    //     }
    //   });
    // },
    // 根据当前路径更新导航项高亮
    updateActiveItem(path) {
      let isAboutUs = path.startsWith("/AboutUs");
      this.navItems.forEach((item, index) => {
        if (
          item.route &&
          (path.startsWith(item.route) ||
            (isAboutUs && item.route.startsWith("/AboutUs")))
        ) {
          this.activeIndex = index;
          item.selected = true;
          if (index === 4 || isAboutUs) {
            this.showAboutUsDrawer = true;
            this.aboutUsGroups = this.navItems[4].aboutUsGroups;
            this.navItems[4].aboutUsGroups.forEach((group, groupIndex) => {
              group.links.forEach((link, linkIndex) => {
                if (link.route && path === link.route) {
                  this.activeGroupIndex = groupIndex;
                  this.activeLinkIndex = linkIndex;
                }
              });
            });
          } else {
            this.showAboutUsDrawer = false;
          }
        } else {
          item.selected = false;
        }
      });
    },
    // 延迟隐藏核心产品列表
    delayedHideCoreProducts() {
      this.hideCoreProductsTimer = setTimeout(() => {
        this.showCoreProductsList = false;
      }, 100); // 延迟100毫秒
    },
    // 取消隐藏核心产品列表
    cancelHideCoreProducts() {
      if (this.hideCoreProductsTimer) {
        clearTimeout(this.hideCoreProductsTimer);
        this.hideCoreProductsTimer = null;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Home_top_box {
  position: relative;
}
.Home_top_left {
  height: 16px;
  display: flex;
  height: 100%;
  align-items: center;
  .Home_top_left_img {
    width: 140px;
    height: 16px;
    display: flex;
    margin: 0 96px 0 36px;
    img {
      width: 100%;
      height: 100%;
    }
    span {
      font-family: PingFang SC;
      font-size: 22px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  ul {
    display: flex;
    height: 100%;
    align-items: center;
    li {
      height: 100%;
      margin: 0 26px 0 26px;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        color: #999999;
        cursor: pointer;
      }
      .active {
        color: #3cc7d6; // 选中项的颜色
      }
    }
  }
}
.Home_top_right {
  display: flex;
  align-items: center;
  .Home_telephone {
    display: flex;
    align-items: center;
    .Home_top_right_img {
      width: 22px;
      height: 22px;
      margin: 0 6px 0 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      font-size: 16px;
      color: #333333;
    }
  }
  .disembark_button {
    width: 120px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background-color: #3cc7d6;
    border-radius: 4px;
    margin: 0 36px 0 20px;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
  }
}
.AboutUs_list {
  top: 40px;
  left: 500px;
  position: absolute;
  width: 516px;
  height: 140px;
  background-color: #ffffff;
  border-radius: 8px;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  ul {
    margin: 10px 10px 0 30px;
    width: 140px;
    li {
      margin: 10px 0 10px 0;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      cursor: pointer;
    }
    .active {
      color: #3cc7d6; // 选中项的颜色
    }
  }
}
.AboutUs_list ul li:first-child {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #333333;
}
.CoreProducts_list {
  top: 60px;
  left: 300px;
  position: absolute;
  background-color: #ffffff;
  z-index: 20;
  width: 260px;
  height: 50px;
  border-radius: 8px;
  ul {
    li {
      width: 100%;
      text-align: center;
      span {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        line-height: 56px;
        cursor: pointer;
        &.active {
          color: #3cc7d6;
        }
      }
    }
  }
}
</style>
