<template>
  <div class="Trait_content_box">
    <div class="Trait_content_top_text">
      <p>大模型技术特点</p>
      <span
        >从大模型基座到大模型Agent，再到可视化应用，知风大语言模型在三个核心层面打通了技术到业务应用</span
      >
    </div>
    <div class="Trait_content">
      <div class="Trait_content_img">
        <img src="@/public/img/Peculiarity_ai.gif" alt="" />
      </div>
      <div class="Trait_List">
        <div class="Trait_List_li">
          <ul>
            <li
              v-for="(tab, index) in tabs"
              :key="index"
              :class="{ different: index === currentIndex }"
              @click="selectTab(index)"
            >
              {{ tab }}
            </li>
          </ul>
        </div>
        <div class="Trait_List_content">
          <p v-if="currentIndex === 0" v-html="aiAbilityContent"></p>
          <p v-if="currentIndex === 1" v-html="saasAbilityContent"></p>
          <p v-if="currentIndex === 2" v-html="aiModelContent"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: ["基座", "Agent", "应用"],
      currentIndex: 0,
      aiAbilityContent: `• 知风大模型采用模型微调、私有化部署RAG和量化优化算法技术，<br/><br/>&nbsp;&nbsp;&nbsp;具有数据安全、部署灵活、可定制化等优势，达到了业内垂类大模型领先水平。<br/><br/>• 采用知识蒸馏技术，AI读取企业信息和专利技术，并对其进行分片处理，<br/><br/>&nbsp;&nbsp;&nbsp;再重新组合为存储向量，显著提升了大模型的效率和泛化能力。<br/><br/>`,
      saasAbilityContent: `• 大模型能力结合科技成果转化、新质生产力引入、研发辅助和投资服务、<br/><br/> &nbsp;&nbsp;&nbsp;技术保护四大应用场景，同时在独有知识库的助力下形成专业领域AI智能体。<br/><br/>• Multi-Agent和LLM组合协作，生成更符合需求的结果，再利用RAG知识库，最终得出结果。<br/><br/>• 能够自动化执行复杂任务，大大提升业务流程的智能化水平。`,
      aiModelContent: `• 融合打造知小风AI助手多场景应用，深度分析与挖掘技术资源，<br/><br/>&nbsp;&nbsp;&nbsp;可智能对话式应答用户，为用户提供定制化人工智能解决方案。<br/><br/>• 创新打造出一站式企业服务平台，该平台是集公司融资、知产、经营、管理、法律等企业管理<br/><br/>&nbsp;&nbsp;&nbsp;为一体的综合性数字化服务平台，致力于为中小企业打通管理壁垒，实现高效运营，让创业成功更容易。`,
    };
  },
  mounted() {
    this.startAutoSwitch();
  },
  beforeDestroy() {
    clearInterval(this.switchInterval);
  },
  methods: {
    selectTab(index) {
      this.currentIndex = index;
    },
    startAutoSwitch() {
      this.switchInterval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.tabs.length;
      }, 4000); // 每4秒切换一次
    },
  },
};
</script>
<style lang="less" scoped>
.Trait_content_box {
  height: 600px;
  width: 100%;
  background-size: cover;
  background-position: bottom; /* 调整为顶部居中显示 */
  background-repeat: no-repeat;
  background-image: url("../../../public/img/Peculiarity_bg.png");
  background-color: #fafafa;
  .Trait_content_top_text {
    margin-top: 5%;
    margin-bottom: 4%;
    width: 100%;
    text-align: center;
    span {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #9999;
    }
    p {
      margin-bottom: 1%;
      font-family: PingFang SC;
      font-size: 30px;
      font-weight: 500;
      line-height: 38px;
      text-align: center;
      color: #000;
    }
  }
  .Trait_content {
    display: flex;
    justify-content: center; /* 水平居中 */
    .Trait_content_img {
      width: 400px;
      height: 400px;
      margin-right: 12%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .Trait_List {
      .Trait_List_li {
        margin-top: 80px;
        ul {
          display: flex;
          width: 768px;
          height: 40px;
          border-bottom: 0.5px solid #ccc;

          li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #999999;
            cursor: pointer;
            transition: color 0.3s, border-bottom-color 0.3s; /* 添加颜色和下划线过渡 */
            position: relative;

            &.different {
              color: #3cc7d6;
              border-bottom: 2px solid #3cc7d6; /* 激活项下划线颜色 */
            }
          }
        }
      }
      .Trait_List_content {
        margin-top: 20px;
        p {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #5b5b5b99;
        }
      }
    }
  }
}
</style>
