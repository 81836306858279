<template>
  <div class="Overview_Content_div">
    <div class="Overview_top">
      <p>公司介绍</p>
    </div>
    <div class="Overview_text">
      北京国知启迪之星企业服务有限公司，由启迪之星(北京)科技企业孵化器有限公司、华智众创(北京)投资管理有限责任公司、国知启迪君才合志(天津)企业管理中心(有限合伙)三家公司联合发起成立的综合性企业服务公司，其中华智众创(北京)投资管理有限责任公司最终实际控制人是中国专利局。<br />
      启迪之星（国知），启迪之星旗下专注于数智化企业管理、以科创平台为核心的综合性科技服务公司，具有知识产权大数据服务能力、企业法律服务能力、企业知产服务能力。<br />
      服务内容基本涵盖企业全生命周期所涉事项，例如办公场地、知识产权、法律咨询、项目申报、资质办理、投融资、科技成果转化等，依托启迪之星孵化器国内外180多家基地（园区）构成的服务网络，可为各类创新创业主体对接所需资源，助力其高质量加速成长！自研的启迪之星一站式科技服务平台，可以称为企业的战略指挥室或企业的虚拟总裁助理。集公司融资、知产、经营、管理、法律等企业管理为一体的综合性数字化服务平台，致力于为中小企业打通管理壁垒，实现高效运营。
    </div>
    <div class="Overview_content">
      <ul>
        <li>
          <div class="Overview_content_img">
            <img src="@\public\img\Introduce_img_01.png" alt="" />
          </div>
          <div>
            <span>公司愿景</span>
            <p>致力于成为全球领先的科创服务提供商</p>
          </div>
        </li>
        <li>
          <div class="Overview_content_img">
            <img src="@\public\img\Introduce_img_03.png" alt="" />
          </div>
          <div>
            <span>公司使命</span>
            <p>做创业企业的数字经济引领者</p>
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="Overview_content_img">
            <img src="@\public\img\Introduce_img_02.png" alt="" />
          </div>
          <div>
            <span>公司价值</span>
            <p>让创业者创业成功更简单</p>
          </div>
        </li>
        <li>
          <div class="Overview_content_img">
            <img src="@\public\img\Introduce_img_04.png" alt="" />
          </div>
          <div>
            <span>公司理念</span>
            <p>与创业企业共成长，把简单留给创业者，将复杂留给我们</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="Overview_content_address">
      <div class="address-box">
        <p>公司荣誉</p>
      </div>
    </div>
    <div class="Overview_honor">
      <div class="Overview_honor_p">
        <p>2021</p>
        <p>2022</p>
        <p>2023</p>
      </div>
      <div class="Overview_honor_SkyStep">
        <SkyStep :stepList="stepList" />
      </div>
      <div class="Overview_honor_list">
        <ul class="Overview_honor_list_ul1">
          <li>
            <img src="@\public\img\honer_2021_img_01.png" alt="" />
            <p>国家知识产权局专利审查协作北京中心实训基地合办单位</p>
          </li>
          <li>
            <img src="@\public\img\honer_2021_img_02.png" alt="" />
            <p>国家知识产权局专利审查协作北京中心知识产权孵化器基地</p>
          </li>
        </ul>
        <ul class="Overview_honor_list_ul2">
          <li>
            <img src="@\public\img\honer_2022_img.png" alt="" />
            <p>河北省省级知识产权信息公共服务网点</p>
          </li>
        </ul>
        <ul class="Overview_honor_list_ul3">
          <li>
            <img src="@\public\img\honer_2022_img_1.png" alt="" />
            <p>专利代理机构执业许可证</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SkyStep from "./SkyStep.vue";
export default {
  components: {
    SkyStep,
  },
  data() {
    return {
      stepList: [
        { label: "", status: "doing" },
        { label: "", status: "doing" },
        { label: "", status: "doing" },
      ],
      currentStep: 0,
      currentYear: 2021,
      years: [2021, 2022, 2023],
      intervalId: null,
    };
  },
  mounted() {
    // this.startInterval();
  },
  // methods: {
  //   startInterval() {
  //     if (this.intervalId) {
  //       clearInterval(this.intervalId);
  //     }
  //     this.intervalId = setInterval(() => {
  //       this.updateStep();
  //     }, 5000);
  //   },

  //   updateStep() {
  //     if (this.currentStep < this.stepList.length - 1) {
  //       this.stepList[this.currentStep].status = "undone";
  //       this.currentStep++;
  //       this.stepList[this.currentStep].status = "doing";
  //     } else {
  //       this.stepList[this.currentStep].status = "undone";
  //       this.currentStep = 0;
  //       this.stepList[this.currentStep].status = "doing";
  //     }

  //     this.currentYear = this.years[this.currentStep];
  //   },
  //   updateYear(year) {
  //     clearInterval(this.intervalId);
  //     this.currentYear = year;
  //   },

  //   resumeAutoUpdate() {
  //     this.stepList[this.currentStep].status = "doing";
  //     this.startInterval();
  //   },
  // },
};
</script>

<style lang="less" scoped>
.Overview_Content_div {
  width: 100vw;
  .Overview_top {
    width: 100%;
    height: 400px;
    background-image: url("../../../public/img/top_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    p {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
  }
  .Overview_text {
    position: absolute;
    top: 320px;
    left: 17.5%;
    width: 1120px;
    height: 234px;
    padding: 20px 60px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // 添加阴影效果
    font-family: "PingFang SC", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 2; // 改变行高，更好地阅读体验
    text-align: left;
    color: #666666; // 改变文本颜色
    overflow: hidden; // 防止内容溢出
    transition: box-shadow 0.3s ease; // 添加平滑的过渡效果
    display: flex; // 使用 Flexbox 布局
    align-items: center; // 垂直居中
    justify-content: center; // 水平居中

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // 鼠标悬停时的阴影效果
    }
  }
  .Overview_content li:hover .Overview_content_img {
    transform: translateY(-8px);
  }

  .Overview_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    ul {
      display: flex;
      flex-direction: column;
      li {
        width: 610px;
        height: 134px;
        background-image: url("../../../public/img/Introduce_img_bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 0 15px 0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        .Overview_content_img {
          width: 200px;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        span {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #333333;
        }
        p {
          margin-top: 15px;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #999999;
        }
      }
    }
    ul:first-of-type {
      margin-right: 8px;
    }
    ul:nth-of-type(2) {
      margin-left: 8px;
    }
  }

  .Overview_content_address {
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;
    .address-box {
      margin-top: 60px;
      p {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        color: #000;
      }
    }
  }
  .Overview_honor {
    width: 100%;
    height: 540px;
    background-image: url("@/public/img/honor_bg.png");
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    .Overview_honor_p {
      width: 960px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-bottom: 20px;
      margin-top: 40px;
      p {
        cursor: pointer;
        font-size: 40px;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        color: #3cc7d6;
        transform: skewX(-6deg);
      }
    }
    .Overview_honor_SkyStep {
      width: 1308px;
      margin: 0 auto;
    }
    .Overview_honor_list {
      width: 1308px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      ul {
        // display: flex;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 380px;
          height: 200px;
          background-color: #ebfffe;
          border-radius: 8px;
          img {
            width: 220px;
            height: 120px;
          }
          p {
            margin-top: 20px;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            text-align: center;
            color: #3cc7d6;
          }
        }
      }
      .Overview_honor_list_ul1 {
        margin-left: 20px;
        li {
          margin-bottom: 15px;
        }
      }
      .Overview_honor_list_ul3 {
        margin-right: 20px;
      }
    }
  }

  .Overview_map {
    width: 100%;
    height: 1100px;
    background-image: url("@/public/img/map.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
