<template>
  <div class="Carousel_content_div">
    <el-carousel
      height="37.5rem"
      :autoplay="true"
      :interval="5000"
      :loop="true"
      trigger="hover"
      arrow="never"
    >
      <el-carousel-item>
        <div class="Platform_div">
          <div class="Platform_name_div">启迪之星AI科创大模型</div>
          <div class="platform_intro">
            从大模型基座到大模型Agent，再到可视化应用，知风大语言模型在三个核心层面打通技术到业务应用，是数据安全、部署灵活、可定制化的AI科创大模型，已达到业内垂类大模型领先水平。
          </div>
          <!-- <div class="Immediate_access_div" @click="redirectToWebsite">
            <span>立即进入</span>
            <img src="@/assets/icon/Icon_into.png" alt="" />
          </div> -->
        </div>
        <div class="Carousel_banner_img">
          <img src="@/public/img/banner_03.png" alt="Banner 3" />
        </div>
      </el-carousel-item>

      <el-carousel-item>
        <div class="Platform_div">
          <div class="Platform_name_div">知小风，您的专属AI助手</div>
          <div class="platform_intro">对话式解决方案，一切都很简单！</div>
          <div class="Immediate_access_div" @click="redirectToNewsCoreProduct">
            <span>了解更多</span>
            <img src="@/assets/icon/Icon_into.png" alt="" />
          </div>
        </div>
        <div class="Carousel_banner_img">
          <img
            src="@/public/img/1cbf476ce7166de826e663942305f2e1.png"
            alt="Banner 2"
          />
        </div>
      </el-carousel-item>

      <!-- <el-carousel-item>
        <div class="video-box">
          <video
            class="video-background"
            loop
            playsinline
            autoplay
            src="@/public/video/banner_01_new.mp4"
            tabindex="-1"
            muted="muted"
          ></video>
        </div>
      </el-carousel-item> -->

      <!-- <el-carousel-item>
        <div class="Platform_div">
          <div class="Platform_name_div">公司动态</div>
          <div class="platform_intro">
            沙特国家知识产权局局长阿卜杜勒阿齐兹·斯瓦勒姆率领代表团在启迪之星进行深入的调研交流活动。
          </div>
          <div class="Immediate_access_div" @click="redirectToNewsUpdates">
            <span>了解更多</span>
            <img src="@/assets/icon/Icon_into.png" alt="" />
          </div>
        </div>
        <div class="Carousel_banner_img">
          <img src="@/public/img/banner_04.png" alt="Banner 4" />
        </div>
      </el-carousel-item> -->
    </el-carousel>
  </div>
  <div class="CardAssembly_box">
    <ul>
      <li class="CardAssembly_list_1">
        <div class="CardAssembly_list_content">
          <span>精准匹配</span>
          <p>
            凭借自然语言处理和泛化能力，精准解析用户真实需求，结合全球技术大数据深度挖掘企业技术资源，实现精准匹配。
          </p>
        </div>
      </li>
      <li class="CardAssembly_list_2">
        <div class="CardAssembly_list_content">
          <span>降本增效</span>
          <p>
            实现数据自动整合与分析，提供详尽项目信息，同时通过模拟人工筛选流程精简工作流程，显著提升工作效率。
          </p>
        </div>
      </li>
      <li class="CardAssembly_list_3">
        <div class="CardAssembly_list_content">
          <span>市场洞察</span>
          <p>
            基于大数据分析预测产业趋势，助力客户决策，同时定制长线任务并主动推送更新资料，持续跟踪。
          </p>
        </div>
      </li>
      <li class="CardAssembly_list_4">
        <div class="CardAssembly_list_content">
          <span>个性化定制</span>
          <p>
            通过记忆用户偏好优化信息提取，支持定制化模型与私有化部署，实现高效算力迭代与个性化服务。
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToWebsite() {
      window.location.href = "https://www.tusstarss.com/smartchain/#/login"; // 替换为您官网的网址
    },

    redirectToNewsUpdates() {
      this.$router.push("/AboutUs/NewsUpdates");
    },
    redirectToNewsCoreProduct() {
      this.$router.push("/CoreProduct");
    },
  },
};
</script>
<style lang="less" scoped>
.Carousel_content_div {
  height: 600px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  .el-carousel {
    width: 100%;
  }

  // .video-box {
  //   width: 1920px;
  //   height: 600px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  .video-background {
    width: 100%;
    height: 100%;
  }

  .Carousel_banner_img {
    width: 100%;
    height: 100%;
    // overflow: hidden;
  }

  .Carousel_banner_img img {
    width: 100%;
    height: 100%;
  }
}
.Platform_div {
  position: absolute;
  top: 170px;
  left: 116px;
  z-index: 1;
  .Platform_name_div {
    font-family: PingFang SC;
    font-size: 40px;
    margin-bottom: 30px;
    color: #000;
  }
  .platform_intro {
    width: 580px;
    font-size: 18px;
    line-height: 24px;
    color: #999999;
    line-height: 28px;
  }
  .Immediate_access_div {
    margin-top: 60px;
    width: 140px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    background-color: #3cc7d6;
    cursor: pointer;
    span {
      font-size: 16px;
      color: #ffffff;
      margin: 0 10px 0 16%;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 60px;
  left: 12%;
  transform: translateX(-50%);
}

.CardAssembly_box {
  width: 100%;
  margin-top: -44px;
  ul {
    display: flex;
    justify-content: center;
    // justify-content: space-between;
    // width: 90%;
    // margin-left: 4.8%;
    li {
      width: 290px;
      height: 170px;
      margin-left: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡 */
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7); /* 遮罩 */
        border-radius: inherit;
        z-index: 1;
        transition: background 0.3s ease; /* 平滑过渡 */
      }
      &:hover {
        transform: translateY(-16px); /* 卡片升起 */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 加强阴影效果 */
      }
      &:hover::before {
        background: rgba(255, 255, 255, 0); /* 去掉遮罩不透明度 */
      }
      .CardAssembly_list_content {
        position: relative;
        z-index: 2;
        color: white;
        padding: 20px 10px 0 20px;
        span {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #000;
        }
        p {
          margin-top: 15px;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #999;
        }
      }
    }
    .CardAssembly_list_1 {
      background-image: url("../../../public/img/kk_area_bg_01.png");
    }
    .CardAssembly_list_2 {
      background-image: url("../../../public/img/kk_area_bg_02.png");
    }
    .CardAssembly_list_3 {
      background-image: url("../../../public/img/kk_area_bg_03.png");
    }
    .CardAssembly_list_4 {
      background-image: url("../../../public/img/kk_area_bg_04.png");
    }
  }
}

/* 使用 ::v-deep 来应用样式 */
::v-deep .el-carousel__button {
  width: 100px;
  height: 3px;
  background-color: #3cc7d6;
  transition: background-color 0.3s;
}
</style>
