import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ResourceCenter from "../views/ResourceCenter.vue";
import CoreProduct from "../views/CoreProduct.vue";
import KejiService from "../views/KejiService.vue";
import AboutUs from "../views/AboutUs.vue";
import ContactUs from "../views/ContactUs.vue";

import Overview from "../views/components/AboutUs/Overview.vue";
import Honors from "../views/components/AboutUs/Honors.vue";
import NewsUpdates from "../views/components/AboutUs/NewsUpdates.vue";
import Events from "../views/components/AboutUs/Events.vue";
import JoinUs from "../views/components/AboutUs/JoinUs.vue";

import NotFound from "../views/NotFound.vue"; // 导入 404 页面组件
import UploadResource from "../views/UploadResource.vue"; // 导入 404 页面组件
const routes = [
  {
    path: "/",
    redirect: "/HomeView",
  },
  {
    path: "/HomeView",
    name: "HomeView",
    component: HomeView,
    meta: { title: "首页" },
  },
  {
    path: "/CoreProduct",
    name: "CoreProduct",
    component: CoreProduct,
    meta: { title: "核心产品" },
  },
  {
    path: "/KejiService",
    name: "KejiService",
    component: KejiService,
    meta: { title: "科技服务" },
  },
  {
    path: "/ResourceCenter",
    name: "ResourceCenter",
    component: ResourceCenter,
    meta: { title: "资源中心" },
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    component: AboutUs,
    meta: { title: "关于我们" },
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs,
    meta: { title: "联系我们" },
  },

  {
    path: "/AboutUs/Overview",
    name: "Overview",
    component: Overview,
    meta: { title: "公司概况" },
  },
  {
    path: "/AboutUs/Honors",
    name: "Honors",
    component: Honors,
    meta: { title: "公司荣誉" },
  },
  {
    path: "/AboutUs/NewsUpdates",
    name: "NewsUpdates",
    component: NewsUpdates,
    meta: { title: "新闻动态" },
  },
  {
    path: "/AboutUs/Events",
    name: "Events",
    component: Events,
    meta: { title: "公司活动" },
  },
  {
    path: "/AboutUs/JoinUs",
    name: "JoinUs",
    component: JoinUs,
    meta: { title: "加入我们" },
  },
  {
    path: "/:catchAll(.*)", // 匹配所有未定义的路径
    name: "NotFound",
    component: NotFound,
    meta: { title: "404 - 页面未找到" },
  },
  {
    path: "/UploadResource",
    name: "UploadResource",
    component: UploadResource,
    meta: { title: "后台" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
