<template>
  <div class="Consultation_maximum_box">
    <ul class="Consultation_ul">
      <!-- <li>
        <img src="@/assets/icon/Icon_connection.png" alt="" />
        <p>在线咨询</p>
      </li> -->
      <!-- <div class="divider"></div> -->
      <li class="Contact_number">
        <img src="@/assets/icon/Icon_telephone.png" alt="" />
        <p>联系我们</p>
        <div class="Contact_number_div">010-57238723</div>
      </li>
      <div class="divider"></div>
      <li>
        <img src="@/assets/icon/Icon_demonstration.png" alt="" />
        <p>预约演示</p>
      </li>
    </ul>
    <div class="Pinned_div" @click="scrollToTop">
      <img src="@/assets/icon/Icon_top.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.Consultation_ul {
  height: 208px;
  width: 72px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 36px;
  text-align: center;
  display: flex;
  flex-direction: column; /* 垂直排列 */
  cursor: pointer;
  li {
    width: 100%;
    height: 70px;
    margin: 14px 0px 14px 0;
    img {
      width: 40px;
      height: 40px;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
      color: #3cc7d6;
    }
  }
  .divider {
    margin-left: 20%;
    display: flex;
    width: 60%;
    height: 1px;
    background-color: #e5e5e5;
  }
}
.Pinned_div {
  margin-top: 24px;
  width: 72px;
  height: 72px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  img {
    width: 48px;
    height: 48px;
  }
}
.Contact_number {
  position: relative;
  &:hover .Contact_number_div {
    opacity: 1;
    visibility: visible;
  }
}
.Contact_number_div {
  position: absolute;
  width: 154px;
  height: 40px;
  top: 20px;
  right: 80px;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  line-height: 40px;
  border-radius: 20px;
  font-size: 16px;
  color: #3cc7d6;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease;
}
</style>
