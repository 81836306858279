<template>
  <div class="Home_content_box">
    <Carousel></Carousel>
    <Trait></Trait>
    <FirmUsasa></FirmUsasa>
    <BottomVideo></BottomVideo>
  </div>
</template>
<script>
import Carousel from "./MainConten/Carousel.vue";
import BottomVideo from "./MainConten/BottomVideo.vue";
import FirmUsasa from "./MainConten/FirmUsasa.vue";
import Trait from "./MainConten/Trait.vue";
export default {
  components: {
    BottomVideo,
    FirmUsasa,
    Carousel,
    Trait,
  },
};
</script>
<style lang="less" scoped></style>
