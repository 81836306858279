<template>
  <div class="NewsUpdates_Content_div">
    <div class="NewsUpdates_top1">
      <p>新闻动态</p>
    </div>
    <div class="NewsUpdates_content1">
      <ul v-for="(news, index) in newsUpdates" :key="index">
        <li @click="openLink(news.wxUrl)">
          <div class="NewsUpdates_img">
            <img :src="news.imgSrc" alt="" />
          </div>
          <span>{{ news.title }}</span>
          <p>{{ news.description }}</p>
        </li>
      </ul>
    </div>
    <!-- <div class="NewsUpdates_top2">
      <p>公司活动</p>
    </div>
    <div class="NewsUpdates_content2">
      <ul>
        <li>
          <div class="NewsUpdates_img">
            <img src="@/public/img/Rectangle70.png" alt="" />
          </div>

          <span>租赁市场需求规模稳步提升，国企参与度进一步加大</span>
          <p>近年来，住房租赁行业已逐渐崭露头角，成为社会各界瞩目的焦点。</p>
        </li>
        <li>
          <div class="NewsUpdates_img">
            <img src="@/public/img/Rectangle71.png" alt="" />
          </div>
          <span>飞凡的悲剧，从荣威时代就已经注定</span>
          <p>
            据电动知家消息，因急需降本和品牌调整，上汽集团已开始实施裁员，从安亭总部到临港工厂的均有涉及，且首当其冲的便是飞凡本部员工。
          </p>
        </li>
        <li>
          <div class="NewsUpdates_img">
            <img src="@/public/img/Rectangle72.png" alt="" />
          </div>
          <span>9个月前刚刚融资13亿美元，如今AI独角兽创始人却带头跑了</span>
          <p>
            3月19日，微软发文称，公司已聘请了Inflection
            AI的CEO穆斯塔法-苏莱曼（Mustafa Suleyman
            ）和首席科学家卡伦-西蒙扬（Karén Simonyan）将前往微软。
          </p>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showImage: false,
      newsUpdates: [
        {
          wxUrl: "https://mp.weixin.qq.com/s/9-dA5DoXbiMYmV0V7f7RZQ",
          imgSrc: require("@/public/img/企业微信截图_17230858321000.png"),
          title: "沙特国家知识产权局局长一行与启迪之星深入交流知识产权国际化",
          description:
            "未来，双方将携手努力，共同推动知识产权保护和创新发展，为全球科技创新做出更大的贡献。",
        },
        {
          wxUrl: "https://mp.weixin.qq.com/s/dZ-NuPhKI48jkmGf9r9qSg",
          imgSrc: require("@/public/img/企业微信截图_17230857968887.png"),
          title: "荣获第六届山东省高层次人才创业大赛西安赛区赛道第一名",
          description:
            "知风AI科创大模型团队将继续致力于技术创新和产品优化，为客户提供更优质的服务，推动行业进步。",
        },
        {
          wxUrl: "https://mp.weixin.qq.com/s/aa2rpyX0hZgLeEppqfsbqg",
          imgSrc: require("@/public/img/企业微信截图_17230858204068.png"),
          title: "荣获“创业北京”区选拔赛二等奖晋级决赛",
          description:
            "知风大模型，结合新质生产力引入、科技成果转化、技术保护等应用场景，国知启迪之星打造前沿的AI应用，为客户提供人工智能解决方案，不断探索和拓展AI技术与业务落地的边界。",
        },
        {
          wxUrl: "https://mp.weixin.qq.com/s/Qei7sx0ccUQ0HBEhjajAww",
          // imgSrc: "",
          imgSrc: require("@/public/img/企业微信截图_20241111143920.png"),
          title:
            "启迪之星-知风科创大模型在“超级计算 智创未来”顶尖科学家论坛上正式发布！",
          description:
            "刘大响院士、陈润生院士、张宏科院士、王军成院士、曹进德院士、董勤喜院士 六位院士共同登台发布！",
        },
      ],
    };
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.NewsUpdates_Content_div {
  width: 100vw;
  background-color: #ffffff;
  .NewsUpdates_top1 {
    width: 100%;
    height: 598px;
    background-image: url("../../../public/img/top_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    p {
      margin-top: 50px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
  }

  .NewsUpdates_content1 {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 20%;
    left: 6.5%;
    width: 1656px;
    height: 426px;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        cursor: pointer;
        height: 382px;
        width: 360px;
        border-radius: 8px;
        padding: 20px;
        background-color: #ffffff;

        .NewsUpdates_img {
          width: 360px;
          height: 216px;
          overflow: hidden;
          margin-bottom: 15px;
          img {
            width: 100%;
            height: 100%;
            transition: transform 0.3s ease;
            cursor: pointer;
          }
        }

        .NewsUpdates_img img:hover {
          transform: scale(1.1);
          transform-origin: center center;
        }
        span {
          // margin-top: 15px;
          font-family: PingFang SC;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          text-align: left;
          color: #333333;
        }
        p {
          margin-top: 8px;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #999999;
        }
      }
    }
  }

  .NewsUpdates_top2 {
    margin-top: 350px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
  }
  .NewsUpdates_content2 {
    width: 1250px;
    height: 426px;
    margin-left: 17.5%;
    margin-bottom: 40px;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        height: 372px;
        width: 360px;
        border-radius: 8px;
        padding: 20px;
        background-color: #ffffff;
        .NewsUpdates_img {
          width: 360px;
          height: 216px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: transform 0.3s ease;
            cursor: pointer;
          }
        }

        .NewsUpdates_img img:hover {
          transform: scale(1.1);
          transform-origin: center center;
        }
        span {
          font-family: PingFang SC;
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          text-align: left;
          color: #333333;
        }
        p {
          margin-top: 20px;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #999999;
        }
      }
    }
  }
}
</style>
