<template>
  <div class="TopBackground_content_div">
    <div class="TopBackground_div">
      <div class="TopBackground_name_div">知小风AI科创助手</div>
      <div class="TopBackground_intro">
        您的专属AI助手，助力用户实现快速匹配科技成果转化资源、精准招商引才，活化技术潜能，促进新质生产力持续发展。
      </div>
    </div>
    <div class="TopBackground_video-box">
      <video
        class="TopBackground_video-background"
        loop
        playsinline
        autoplay
        src="@/public/video/banner_01_new.mp4"
        tabindex="-1"
        muted="muted"
      ></video>
    </div>
    <!-- <div class="TopBackground_tap">
      <ul>
        <li><span>一站式平台概览</span></li>
        <li><span>一站式平台优势</span></li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.TopBackground_content_div {
  height: 600px;
  width: 100vw;
  overflow: hidden;
  position: relative;

  .TopBackground_video {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .TopBackground_video-background {
    width: 100%;
    height: 100%;
    // object-fit: cover; /* 使视频完全覆盖父容器并保持宽高比 */
    // position: absolute;
    // top: 0;
    // left: 0;
  }
}
.TopBackground_div {
  position: absolute;
  top: 28%;
  left: 116px;
  z-index: 1;
  .TopBackground_name_div {
    font-family: PingFang SC;
    font-size: 40px;
    margin-bottom: 20px;
    color: #000;
  }
  .TopBackground_intro {
    width: 580px;
    font-size: 18px;
    line-height: 28px;
    color: #999999;
  }
}
.TopBackground_tap {
  position: absolute;
  width: 100%;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  ul {
    display: flex;
    width: 100%;
    li {
      text-align: center;
      width: 50%;
      span {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        line-height: 56px;
        cursor: pointer;
      }
    }
  }
}
</style>
