<template>
  <div class="Resource_Center">
    <div class="Resource_Center_div">
      <ul v-for="item in tableData" :key="item.id">
        <li @click="openLink(item.wxUrl)">
          <div class="Resource_Center_img">
            <img
              :src="
                `https://www.tusstarss.com/searchCompany/getPic?url=` +
                item.picUrl
              "
              alt=""
            />
          </div>
          <div class="Resource_Center_text">
            <h1>{{ item.title }}</h1>
            <p class="Resource_Center_p">{{ item.lastdate }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="fy">
      <el-pagination
        background
        @current-change="handlePageChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="totalItems"
        layout="prev, pager, next"
      />
    </div>
  </div>
</template>

<script>
import { getResourceCentreList, getPic } from "../request/index";

export default {
  name: "ResourceCenter",
  data() {
    return {
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 3, // 每页显示的数据条数
      totalItems: 0, // 总数据条数
    };
  },
  mounted() {
    this.fetchData(); // 组件挂载时获取数据
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    async fetchData(page = 1, limit = 3) {
      try {
        const params = { page, limit };
        const response = await getResourceCentreList(params);
        this.tableData = this.processData(response.data);
        console.log(this.tableData, "222222222222");
        this.totalItems = response.count;
        // await this.fetchImageData();
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },
    // async fetchImageData() {
    //   // 遍历 tableData 获取每个 item.image 的数据流
    //   for (const item of this.tableData) {
    //     try {
    //       const getPicRes = await getPic({ url: item.picUrl });
    //       // 假设 getPicRes 返回的是图片数据流，可以将其转换为 URL
    //       item.imageUrl = URL.createObjectURL(new Blob([getPicRes.data]));
    //     } catch (error) {
    //       console.error("获取图片数据失败:", error);
    //       // 如果获取图片数据失败，可以设置一个默认图片
    //       // item.imageUrl = "@/public/img/default-image.png"; // 默认图片路径
    //     }
    //   }
    // },
    formatDate(isoString) {
      const date = new Date(isoString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    processData(data) {
      return data.map((item) => {
        const lastDate = new Date(item.lastdate);
        const currentDate = new Date();

        const diffTime = Math.abs(currentDate - lastDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        let displayDate;
        if (diffDays === 0) {
          displayDate = "今天";
        } else if (diffDays === 1) {
          displayDate = "昨天";
        } else if (diffDays > 2) {
          displayDate = this.formatDate(item.lastdate);
        }

        return {
          ...item,
          lastdate: displayDate, // 更新 lastdate 字段为处理后的 displayDate
        };
      });

      // return data.map((item) => ({

      //   ...item,
      //   lastdate: this.formatDate(item.lastdate),
      //   // imageUrl: item.imageUrl,
      // }));
    },

    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData(page, this.pageSize); // 获取当前页的数据
    },
  },
};
</script>

<style lang="less" scoped>
.Resource_Center {
  text-align: center;
  background-color: #fafafa;
  padding: 10px;
}
.fy {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.Resource_Center_div {
  display: inline-block;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 20px;

    li {
      width: 1208px;
      height: 332px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      position: relative;
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      cursor: pointer;
      .Resource_Center_img {
        width: 500px;
        height: 300px;
        overflow: hidden;
        border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
          transition: transform 0.3s ease;
          cursor: pointer;
        }
      }

      .Resource_Center_img img:hover {
        transform: scale(1.1);
        transform-origin: center center;
      }
      .Resource_Center_text {
        width: 644px;
        margin: 20px 0px 0px 20px;
        position: relative;
        h1 {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          text-align: left;
          color: #333333;
          margin-bottom: 15px;
          cursor: pointer;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #999999;
        }
        .Resource_Center_p {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
    }
  }

  /* 手机屏幕调整 */
  // @media (max-width: 768px) {
  //   ul {
  //     li {
  //       width: 100%;
  //       height: auto;
  //       flex-direction: column;
  //       padding: 10px;

  //       .Resource_Center_img {
  //         width: 100%;
  //         height: auto;
  //         margin: 0;
  //       }
  //     }
  //   }
  // }

  // @media (max-width: 576px) {
  //   ul {
  //     li {
  //       padding: 5px;
  //       .Resource_Center_img {
  //         width: 100%;
  //         height: 200px;
  //       }
  //     }
  //   }
  // }
}
/* 居中对齐 */
</style>
