<template>
  <div class="ContactUs_Content_div">
    <div class="ContactUs_top">
      <p>您可以通过以下方式联系我们</p>
    </div>
    <div class="ContactUs_List">
      <ul>
        <li>
          <img src="@\public\img\khzx.jpg" alt="" />
          <div>
            <p>业务相关问题咨询</p>
            <span>了解平台业务问题，定制专属方案</span>
          </div>
        </li>
        <li>
          <img src="@\public\img\khzx.jpg" alt="" />
          <div>
            <p>科创平台技术解决咨询</p>
            <span>描述你的需求，快速解决问题</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="ContactUs_content">
      <p>办公地址</p>
    </div>
    <div class="ContactUs_content_address">
      <div class="address-box">
        <div class="address-box_div">
          <p>北京总部</p>
          <ul>
            <li>
              <img src="@/assets/icon/Icon_map.png" alt="" />
              <span
                >办公地点：北京市海淀区清华科技园启迪科技大厦C座启迪之星</span
              >
            </li>
            <li>
              <img src="@/assets/icon/Icon_phone.png" alt="" />
              <span>热线电话：010-57238723</span>
            </li>
          </ul>
        </div>
        <div class="address-box_div">
          <p>雄安公司</p>
          <ul>
            <li>
              <img src="@/assets/icon/Icon_map.png" alt="" />
              <span>办公地点：河北省保定市安新县旅游东路67号A214启迪之星</span>
            </li>
            <li>
              <img src="@/assets/icon/Icon_phone.png" alt="" />
              <span>热线电话：010-57238723</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ContactUs_content_address">
      <div class="address-box">
        <div class="address-box_div" style="margin-right: 61%">
          <p>济南研发中心</p>
          <ul>
            <li>
              <img src="@/assets/icon/Icon_map.png" alt="" />
              <span>办公地点：山东省济南市市中区建设路99-5号启迪之星</span>
            </li>
            <li>
              <img src="@/assets/icon/Icon_phone.png" alt="" />
              <span>热线电话：010-57238723</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ContactUs_map"></div>
  </div>
</template>

<script></script>

<style lang="less" scoped>
.ContactUs_Content_div {
  width: 100vw;
  .ContactUs_top {
    width: 100%;
    height: 400px;
    background-image: url("../public/img/top_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;
    text-align: center; /* p 标签文本居中 */
    position: relative;
    p {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
  }
  .ContactUs_List {
    position: absolute;
    top: 380px;
    left: 17.5%;
    ul {
      display: flex;
      li {
        width: 586px;
        height: 170px;
        margin-left: 10px;
        border-radius: 8px;
        background-color: #ffffff;
        display: flex;
        padding: 10px;
        img {
          width: 132px;
          height: 132px;
          margin: 15px;
        }

        div {
          margin: 20px 0 0 15px;
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #333333;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            color: #999999;
          }
        }
      }
    }
  }
  .ContactUs_content {
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;
    margin-top: 196px;
    p {
      width: 100%;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
  }
  .ContactUs_content_address {
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;
    margin-top: 37px;
    .address-box {
      width: 1208px;
      height: 172px;
      background-image: url("../public/img/map_bg 1.png");
      background-size: auto 100%; /* 使图片在垂直方向填满容器，高度为 100% */
      background-position: calc(120% - 10px) center; /* 向右偏移 10px */
      background-repeat: no-repeat; /* 不重复 */
      color: #333;
      display: flex; /* 启用 Flexbox 布局 */
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中（如果需要） */
      .address-box_div {
        margin: 15px 94px;
        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #333333;
        }
        ul {
          margin-top: 10px;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            img {
              width: 22px;
              height: 22px;
            }
            span {
              margin-left: 10px;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              color: #333333;
            }
          }
        }
      }
    }
  }
  .ContactUs_map {
    width: 100%;
    height: 1100px;
    background-image: url("../public/img/map.png");
    background-size: contain; /* 使图片按比例缩放，以适应容器 */
    background-position: center; /* 图片居中对齐 */
    background-repeat: no-repeat; /* 图片不重复 */
  }
}
</style>

<!-- <template>
  <div class="ContactUs_Content_div">
    <div class="ContactUs_top">
      <p>您可以通过以下方式联系我们</p>
    </div>
    <div class="ContactUs_List">
      <ul>
        <li>
          <img src="@/public/img/khzx.jpg" alt="" />
          <div>
            <p>业务相关问题咨询</p>
            <span>了解平台业务问题，定制专属方案</span>
          </div>
        </li>
        <li>
          <img src="@/public/img/khzx.jpg" alt="" />
          <div>
            <p>一站式科技服务平台技术解决咨询</p>
            <span>描述你的需求，快速解决问题</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="ContactUs_content">
      <p>办公地址</p>
    </div>
    <div class="ContactUs_content_address">
      <div class="address-box">
        <div class="address-box_div">
          <p>启迪之星北京总部</p>
          <ul>
            <li>
              <img src="@/assets/icon/Icon_map.png" alt="" />
              <span
                >办公地点：北京市海淀区清华科技园启迪科技大厦C座启迪之星</span
              >
            </li>
            <li>
              <img src="@/assets/icon/Icon_phone.png" alt="" />
              <span>热线电话：010-57238723</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ContactUs_map">

    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style lang="less" scoped>
.ContactUs_Content_div {
  width: 100vw;

  .ContactUs_top {
    width: 100%;
    height: 400px;
    background-image: url("../public/img/top_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    p {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
  }

  .ContactUs_List {
    // position: absolute;
    // top: 300px;
    // left: 100px;
    margin: 30px auto;
    width: 90%;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        width: 100%;
        max-width: 500px;
        height: auto;
        margin: 10px 0;
        border-radius: 8px;
        background-color: #ffffff;
        display: flex;
        padding: 10px;
        img {
          width: 132px;
          height: 132px;
          margin: 15px;
        }
        div {
          margin: 20px 0 0 15px;
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #333333;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            color: #999999;
          }
        }
      }
    }
  }

  .ContactUs_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 160px;
    p {
      width: 100%;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000;
    }
  }

  .ContactUs_content_address {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
    .address-box {
      width: 90%;
      height: auto;
      background-image: url("../public/img/map_bg 1.png");
      background-size: auto 100%;
      background-position: calc(120% - 10px) center;
      background-repeat: no-repeat;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;
      .address-box_div {
        width: 90%;
        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #333333;
        }
        ul {
          margin-top: 10px;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            img {
              width: 22px;
              height: 22px;
            }
            span {
              margin-left: 10px;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              color: #333333;
            }
          }
        }
      }
    }
  }

  .ContactUs_map {
    width: 100%;
    height: 500px;
    background-image: url("../public/img/map.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media (min-width: 768px) {
    .ContactUs_List {
      position: absolute;
      top: 300px;
      left: 200px;
      width: 80%;
      display: flex;
      justify-content: center;
      ul {
        width: 76%;
        flex-direction: row;
        justify-content: space-between;
        li {
          width: 45%; /* Adjust the width of li to fit better on wider screens */
        }
      }
    }

    .ContactUs_content_address .address-box {
      width: 1208px;
      height: 172px;
      background-position: calc(100% - 10px) center;
    }

    .ContactUs_map {
      height: 1100px;
    }
  }
}
</style> -->
