<template>
  <div class="Bottom_video_div">
    <div class="video-box">
      <video
        class="video-background"
        preload="auto"
        loop
        playsinline
        autoplay
        src="@/public/video/movie_bg.mp4"
        tabindex="-1"
        muted="muted"
      ></video>
    </div>
    <div class="overlay-text">
      <p>志合者，不以山海为远</p>
      <p>与晨同光，与君同行，携手未来</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Bottom_video_div {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.video-box {
  position: relative;
  width: 100%;
  height: auto;
}

.video-background {
  width: 100%;
  height: auto;
  display: block;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 28%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 50px;
  line-height: 1.5;
  font-weight: 100;
  opacity: 0.8;
}
</style>
