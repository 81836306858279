<template>
  <div class="not-found">
    <h1>404 - 页面未找到</h1>
    <p>抱歉，您访问的页面不存在。</p>
    <router-link to="/">返回首页</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 100px;
}

.not-found h1 {
  font-size: 48px;
  color: #ff4040;
}

.not-found p {
  font-size: 24px;
  color: #333;
}

.not-found a {
  font-size: 18px;
  color: #3cc7d6;
  text-decoration: none;
}
</style>
